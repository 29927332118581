.jobBox{
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    overflow-y: hidden;
}
.jobBox__input textarea{
    overflow-y: scroll !important;
    height: 100px !important;
    resize: none !important;
    padding: 3rem 0 3% 0;
    max-width: 100%;
    font-size:1.6rem;
    border: none;
    outline: none;
    font-family: inherit !important;
    font-weight: 499 !important;
    color: inherit !important;
    width: 80%;
    border-bottom: 2px solid gray;
    margin-bottom: 2%;
}
.jobBox__input input{
    width: 80%;
    flex: 1 1;
    border: none;
    font-size: 1.6rem;
    outline: none;
    padding: 1rem 0 2rem 0;
    border-bottom: 2px solid gray;
    margin-bottom: 3%;

}

.jobBox__input select{
    width: 80%;
    margin-top: 1rem;
    border: none;
    border-bottom: 2px solid black;
}
.jobBox__ava{
    padding: 2rem;
    display: flex;
}

.jobBox__ava p{
    font-size: 2.5rem;
    padding-left: 5%;
}

.jobBox__input-image{
    position: relative;
    margin-bottom: 1rem;
}

.jobBox__input-image::after{
    display: block;
    content: '';
    padding-top: 56.25%;
}

.jobBox__input-image>img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;

    opacity: 1; 
    transition: opacity 1.5s ease-out;
}

.cancelIcon{
    font-size: 3.5rem !important;
    position: absolute;
    top: .5rem;
    left: .5rem;
    z-index: 2;
    cursor: pointer !important;
}

.cancelIcon:hover{
    opacity: 0.6;    
}

.editImage{
    background-color: black !important;
    border: none !important;
    color: white !important;
    font-weight: 500 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 3rem;
    position: absolute !important;
    right: .8rem;
    top: .7rem;
    font-size: 1.6rem !important;
}

.editImage:hover{
    opacity: 0.6;
}


.jobBox__input-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1;
    margin-left: -.8rem;
}

.jobBox__input-icons>div{
    padding: .6rem;
    border-radius: 50%;
    cursor: pointer;
}

.jobBox__input-icons>div:nth-child(3){
    transform: rotate(90deg);
}

.jobBox__input-icons>div:hover{
    background-color: #f5f8fa;
}

.jobBox__input-icons .MuiSvgIcon-root{
    font-size: 2rem;
    color: #ffc300;
    cursor: pointer;
}


.jobBox__button{
    background-color: #ffc300 !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 4rem;
    width: 12rem;
    margin-top: 2rem !important;  
    font-size: 1.4rem !important;
    margin-bottom: 5% !important;
    margin-left: -5rem;
}


.jobPost__select{
    font-size: 1.6rem;
    opacity: 0.7;
    padding: 5px 0;
    margin-bottom: 10px;
}


.jobPosting__box__home{
    margin-top: 4%;
}

.youtube__click__info{
    font-size: 2rem;
    color: gray;
}

.youtube__info{
    border: none;
    background-color: transparent;
    z-index: 100;
}

.youtube__info__div{
    width: 100%;
    padding: 2% 0;
}

.youtube__details li{
    padding-top: 3%;
    font-size: 1.5rem;
    letter-spacing: 1px;
}

.youtube__details ol{
    padding-left: 20%;
}

.youtube__details strong{
    color: red;
}
.salary-input{
    width: 40% !important;

}
.salary-input input{
    height: 5px !important;
    width: 5px !important;
    margin: 1rem 2rem 0 0 !important;
    padding: 10px;
}

.salary-input p{
    font-size: 1.8rem;
    margin-top: 1.2rem;
    margin-right: 8px;
}

@media screen and (max-width: 500px){
    .jobBox{
        margin-bottom: 10%;
    }
    .youtube__details img{
        width: 440px !important;
    }

    .youtube__details ol{
        padding-left: 10%;
    }

}