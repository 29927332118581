
.notification__content strong{
    padding-right: 1rem;
}

.date__notification{
    position: absolute;
    right: 0;
    margin-right: 32%;
}

.notification__bar{
    width: 100%;
    padding: 1% 0 1% 6px;
    margin-bottom: 2rem;
    border-bottom: 1px solid lightgray;
}

.notification__bar:hover{
    background-color: whitesmoke;
    border-radius: 15px;
}

.notification__bar a{
    display: flex;
}

.notification__bar p{
    padding-left: 1rem;
    padding-top: 1.2%;
    font-size: 1.4rem;
}
.notification__content{
    width: 62%;
    margin-top: 2rem;
}

.unread__notification{
    background-color: #eee;
    border-radius: 15px;
}

.unread__notification:hover{
    background-color: lightgray;
    opacity: 0.7;
}

.noNotification__content{
    margin-top: 5rem;
}
.noNotification__content img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}

.noNotification__content p{
    font-size: 2rem;
    text-align: center;
    margin-top: 3rem;
}


@media screen and (max-width: 768px){
    .noNotification__content img{
        width: 60%;
    }
    .noNotification__content p{
        font-size: 1.4rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px){
    .sidebarChat__noExist img{
        width: 50%;
    }
    .how__toStart p{
        font-size: 2rem;
    }
}

