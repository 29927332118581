.footerIcon_wrapper{
    flex: 1;
    font-size: 1.4rem;
    display: flex;
    font-weight: 400;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: gray;
    cursor: pointer;
}

.footerIcon_wrapper .MuiSvgIcon-root {
    font-size: 1.8rem;
    color: #ffc300;
    border-radius: 50%;
}

.footerIcon_wrapper .MuiSvgIcon-root:hover{
    background-color: var(--copipel-background);
}

.footerIcon__counter{
    margin-left: 1.5rem;
}

.liked .MuiSvgIcon-root{
    color: rgb(224, 36, 94) !important;
}

.whoLikes__show button{
    margin: 0 auto;
    display: block;
    padding: 4px 8px;
    background-color: orange;
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 4px;
}

.whoLikes__show{
    margin-left: 1rem;
}

