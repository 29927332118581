.sidebar__account__modal h3{
    margin-top: 1rem;
}

.sidebar__account__modal-edit-each span{
    font-weight: bold;
    position: absolute;
    right: 0;
    margin-right: 20%;
    opacity: 0.6;
}

.sidebar__account__modal-edit-each-btn{
    padding: 6px 12px;
    font-size: 1.4rem;
    margin: 3% 2%;
    border-bottom: 1px solid #eee;
}

.sidebar__account__modal-edit-each input{
    width: 65% !important;
    margin: 0 2%;
    padding: 6px 12px;
    font-size: 1.4rem;
    border: none;
    border-bottom: 1px solid #eee;
}

.sidebar__account__modal-edit-each button{

    letter-spacing: 2px;    
}

.sidebar__account__modal-email{
    border-bottom: 1px solid #eee;
    margin: 0 2%;
}

.sidebar__account__modal-email p{
    font-size: 1.4rem;
    margin-top: 7px;
    margin-left: 5px;
}
.setting-circle{
    font-size: 1.8rem;
    margin-left: 3rem;
}

.sidebar__account__modal h3{
    margin-left: 5%;
    font-size: 1.4rem;
}

.sidebar__notification__modal{
    margin: 5% 10%;
}

.sidebar__account__modal-notification{
    margin: 4% 0 0 5%;
    border-bottom: 2px solid gray;
    padding-bottom: 3px;
}

.sidebar__account__modal-notification p{
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 5px;
}

.switch-btn{
    margin-left: auto; 
    margin-right: 3rem;
}

.switch-btn-email{
    margin-left: auto; 
    margin-right: 4rem;
}

/*Modal Base*/
.modal-box p{
    font-size: 12.5px;
    text-align: center;
}

.modal__under-card-btn{
    width: 100% !important;
}

.modal__under-card-btn button{
    border: none;
    padding: 5px 5px 5px 10px;
    margin: 0 7px 8px 7px;
    background-color: transparent;
    border-left: 1px solid silver;
    top: 50%;
    bottom: 0;
}
.modal__under-card-btn button:hover{
    opacity: 0.6;
}
.modal-icon{
    font-size:1.2rem;
    padding-left: 5px;
}

.modal-box-card{
    width: 11rem;
    padding: 12px !important;
    display: flex;
    justify-content: center;
    margin: 2rem 10px;
}
.modal-flex-block{
    display: flex;
}

.timeline__info-flex{
    display: flex;
}

.sidebar__account-btn{
    margin-left: 60%;
    padding: 3px 6px;
}

@media (max-width: 480px){
    .modal-flex-block{
        display: block;
        margin: 0 auto;
    }
    .modal-box-card{
        margin: 1rem 0;
    }
    .timeline__info-flex{
        margin: 0.6rem auto;
    }
   
}