.mobile__top__nav{
    display: none;
}

.company__dashboard-nav-mobile{
    width: 100%;
    display: none;
}



@media (max-width: 500px){
    .mobile__top__nav__img img{
        width: 180px;
        margin: 10px 0 0 2px;
        padding: 0 !important;
    }
    
    .mobile__top__nav{
        width: 100%;
        height: 5rem;
        margin-bottom: 10%;
        border-bottom: 1px solid lightgray;
        display: block !important;
        background-color: white;
    }
    
    .mobile__btn{
        margin: 3% 5% 0 12%;
    }

    
    .mobile__btn button{
        padding: 6px 10px;
        font-weight: bold;
        font-size: 1.2rem;
        background-color: orange;
        color:white;
        border: none;
        border-radius: 30px;
    }

    .mobile__btn button:hover{
        background-color: orange;
        color: white;
    }
    
    .mobile__nav__icon{
        transform: scale(2);
    
    }

    .menuBarInfo__btn{
        margin: 15px 5px 5px 5px !important;
        height: 3rem;
        background-color:transparent;
        border-radius: 50%;
        border: none;
    }

    .menuBarInfo__btn:hover{
        background-color: lightgray;
    }
    .company__dashboard-nav-mobile-item{
        display: flex;
        margin-bottom: 3%;
    }
    .company__dashboard-nav-mobile{
        display: block !important;
    }
    .sidenavigation-menu-active-mobile{
        background: white;
        opacity: 1;
        border-bottom: 4px solid #432bfb;
        color: #432bfb ;
        padding: 1.2rem 0 1.2rem 1.2rem;
    }
    .sidenavigation-menu-mobile{
        padding: 1.2rem 0 1.2rem 1.2rem;
        display: block;
        color: gray;
        font-weight: bolder;
        opacity: .8;
        font-size:2rem !important;
        margin: 0 1rem;
    }

}