/*Privacy Modal*/
.privacy__modal__buttons{
    padding: 6% 20% 5% 20%;
}

.privacy__modal__buttons button{
    width: 100%;
    padding: 8px 20px;
    margin-top: 6%;
    border: none;
    background-color: transparent;
    font-weight: 600;
}

.privacy__modal__buttons p{
    font-size: 1.3rem;
}

.privacy__modal__buttons button:hover{
    background-color: #f5f5f5;
    border-radius: 6px;
}

/*About Us*/
.about-us{
    background-color: #f7f7f7;
    width: 100%;
    height: 120vh;
}
.about__us__img img{
    width: 60%;
    height: 60%;
}

.about__us__meg{
    float: right;
    margin-right: 5rem; 
    z-index: 100;
}

.about__us__meg h2{
    font-size: 1.8rem;
}

.about__us__meg p{
    font-size: 1.3rem;
    margin: 1rem 0 0 1rem
}

/*Help Center*/
.help__center{
    margin: 5rem 0 0 4rem;
}

.help__center-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 10px 20px;
    color: black;
}
.help__center-card button:hover{
    opacity: 0.7;
}
.help__center__content-btn{
    margin: 4% auto;
    display: flex;
    justify-content: space-evenly;
}

.help__center-card p{
    text-align: center;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.help__center-icon{
    font-size: 3rem;
}

.help__center__content{
    margin: 0 20%;
}

.contact-content-input{
    width: 100%;

}
.contact-content-input-input{
    width: 100%;
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.contact-content-input button{
    padding: 10px 20px;
    font-weight: bold;
    color: white;
    background-color: lightsalmon;
    border: none;
    border-radius: 5px;
    margin: 1rem auto;
    width: 45%;
    display: block;
}
.help__center-or{
    position: relative;
    padding: 0 65px;
    text-align: center;
    margin-top: 4rem;
  }
  
.help__center-or:before {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: #000;
}
  
.help__center-or span {
    position: relative;
    padding: 0 1em;
    background: #fff;
  }

.help__center h3{
    text-align: center;
    font-size: 1.5rem;
}


/*How to use*/

.how__to__use{
    width: 100%;
}
.how__to__use__content h3{
    font-size: 1.5rem;
}

.details-btn{
    font-size: 1.6rem;
    font-weight: bold;
    padding: 4px 20px;
    display: block;
    margin: 0 auto 2rem auto;
    background-color: transparent;
    border: none;
    opacity: 0.7;
}

.how__to__use-1-flex{
    display: flex;
    justify-content: space-evenly;
    margin: 4rem 0;
}

.how__to__use-2{
    background-color: #eee;
}

.how__to__use-3{
    margin-top: 6rem;
}

.how__to__use__content-box-tab button{
    border: 1px solid lightgray;
    padding: 1.5rem;
    border-radius: 50%;
    margin: 1rem;
    background-color: transparent;
}

.how__to__use__content-box-tab button:hover{
    opacity: 0.7;
    background-color: #eee;
}

.tabs-icon{
    font-size: 1.6rem;
}

.first__message{
    margin: 1rem 10%;
    font-size: 1.5rem;
}

.first__message h2{
    position: relative;
    margin: 30px;
    text-align: center;
}

.first__message h2::before, .first__message h2::after{
    position: absolute;
    z-index: 0;
    bottom: -10px;
    display: block;
    content: '';
    border: 1em solid #d90606;
}

.first__message h2::before{
    left: -30px;
    border-left-width: 15px;
    border-left-color: transparent;
}

.first__message h2::after{
    right: -30px;
    border-right-width: 15px;
    border-right-color: transparent;
}

.first__message h2 span{
    position: relative;
    z-index: 1;
    display: block;
    padding: 1rem 2rem;
    color: #fff;
    background: #fa4141;
}

.first__message h2 span::before, .first__message h2 span::after{
    position: absolute;
    bottom: -10px;
    display: block;
    width: 10px;
    height: 10px;
    content: '';
    border-style: solid;
    border-color: #b70505 transparent transparent transparent;
}

.first__message h2 span::before{
    left: 0;
    border-width: 10px 0 0 10px;
}

.first__message h2 span::after{
    right: 0;
    border-width: 10px 10px 0 0;
}

.first__message-content{
    margin: 2rem;
}

.first__message-content h3{
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-decoration: underline;
}

.first__message-content button{
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
}


@media (max-width: 480px){
    .help__center{
        margin: 2rem 0;
    }
    .help__center-card p{
        font-size: 1rem;
    }

    .help__center-icon{
        font-size: 2rem;
    }

    .help__center-card{
        padding: 5px;
    }
}

