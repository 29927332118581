.editProfile__theme{
    background: white;
}

.editProfile__theme-photoWrapper{
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    margin-left: 35%;
    background: whitesmoke;
}

.editProfile .MuiSvgIcon-root{
    font-size: 2.75rem !important;
    color: white;
}


.editProfile__theme-photoWrapper>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
}

.editProfile__theme-photoWrapper>div{
    position: absolute;
    top: 15%;
    left: 47%;
    padding: 1rem;
    border-radius: 50%;
    transform: translate(-45%, -45%);
    cursor: pointer;
}

.editProfile__theme-photoWrapper>div:hover{
    background: whitesmoke;
    opacity: 0.6;
}

.editProfile form{
    margin-top: 3.5rem;
    width: 100%;
}

.editProfile .MuiFormControl-root{
    width: 100%;
}

.editProfile .MuiFilledInput-input{
    font-size: 1.5rem
}

.editProfile .MuiInputLabel-filled.MuiInputLabel-shrink{
    padding: 0 2.6rem;
}

.editProfile .MuiInputLabel-filled{
    font-size: 1.6rem;
    font-weight: 700;
    padding: 0 2rem;
}

.editProfile .MuiFormLabel-root.Mui-focused{
    color: #ffc300 !important;
}

.editProfile .MuiFilledInput-root{
    background-color: whitesmoke !important;
    padding: 0 2rem;
}

.editProfile .MuiFormControl-root:nth-child(3){
    border-radius: 0 0 2rem 2rem;
}

.editProfile .MuiFilledInput-underline:before{
    border-bottom: none 
}

.editProfile .MuiFilledInput-underline:after{
    border-bottom: 3px solid orange;
}
.text__box label{
    font-size: 18px;
}
.text__box textarea{
    font-size: 20px;
    padding-top: 7%;
}


.editProfile__attention{
    font-size: 16px;
    padding-top: 1rem;
}


@media screen and (max-width: 500px){
    .editProfile__theme-photoWrapper>div{
        left: 51%;
    }

}