@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,800);
.copyRight__footer p{
    text-align: center;
    font-size: 1.4rem;
    margin: 1rem 0;
}
.internet-error {
    height: 60px;
    background: #ff8100;
    margin-top: 0;
    font-size: 20px;
  }
  
  .internet-error p {
    font-size: 25px;
    line-height: 60px;
    color: #fff;
    margin: 0;
  }
.sidebarOption{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.sidebarOption:hover{
    color:#FFC300;
    transition: color 100ms ease-out;
    opacity: 0.7;
}

.sidebar__active{
    color: #FFC300;
}

.sidebarOption>h2{
    font-weight: 700;
    font-size: 1.9rem;
    margin-right: 2rem;
}

.sidebarOptionIcon{
    font-size: 2.4rem;
    padding: 1.4rem 1.4rem 1.4rem 2rem;  
}
.hide{
    display: none;
}
.sidebar__unread{
    height: 2rem;
    width: 2rem;
    text-align: center;
    border-radius: 50%;
    background-color: red;
    color: white;
}
@media screen and (max-width: 1282px){
    .sidebarOption>h2{
        display: none;
    }
    .sidebarOption > .MuiSvgIcon-root{
        margin-left: auto;
    }
    .sidebarOption{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}
.sidebar{
    flex: 0.2 1;
    padding-top: 2rem;
    padding-left:2rem;
    padding-right:2rem;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.sidebar__twitterIcon{
    color: orange;
    font-size: 3rem !important;
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: -.3rem;
}

.sidebar__tweet{
    background-color: orange !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 5rem;
    margin-top: 2rem !important;

    width: 100%;
}

.sidebar__tweet>div{
    max-width: 3rem;
    margin-top: .3rem;
    margin-left: .3rem;
}
.sidebar__tweet:hover{
    opacity: 0.7;
}
.sidebar__info{
    font-size: 1.7rem;
    padding: 1.5rem 0 0 1rem;
    font-weight: 600;
    border-bottom: 1px solid gray;
}
.sidebar__info:hover{
    opacity: 0.7;
}

.email__settings{
    width: 100%;
    display: flex;
}

@media screen and (max-width: 1282px){
    .sidebar__tweet{
        width: 5rem !important;
    }
    .sidebar{
        flex: unset !important;
    }
    .sidebar__tweet span{
        display: none;
    }
}


@media screen and (max-width: 500px){
    .sidebar__tweet{
        margin-left: 1rem !important;
    }
    .sidebar{
        display: none;
    }

}

@media screen and (min-width: 1282px){
    .sidebar__tweet>div{
        display: none
    }
}


.sidebarAccount__wrapper{
    display: flex;
    flex-direction: row;
    border-radius: 3rem;
    align-items: center;
    margin-top: 15%;
}


.sidebarAccount__ava{
    border-radius: 50%;
    padding: 1rem;
}

.sidebarAccount__wrapper:hover{
    background-color: #FFC300;
    opacity: 0.4;
    cursor: pointer;
}

.sidebarAccount__userData{
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.sidebarAccount__userData h2:nth-child(2){
    color: black;
    font-weight: 450;
}

.sidebarAccount__expandIcon{
    margin-right: 1rem;
    font-size: 2rem;
}

.MuiPopover-paper  {
    border-radius: 2rem !important;
}

.logoutBtn{
    cursor: pointer;
}

.sideAccount__btn{
    width: 100%;
    padding: 5px 10px;
    border: none;
    background-color: transparent;
}

.sidebar__icon{
    width: 50px;
    height: 50px;
    margin-left: 12%;
}
.setting-icon{
    font-size: 1.8rem;
    position: absolute;
    left: 0;
    margin-left: 25%;
}
@media screen and (max-width: 1282px){
    .sidebarAccount__userData, .sidebarAccount__expandIcon {
        display: none;
    } 
}
.sidebar__account__modal h3{
    margin-top: 1rem;
}

.sidebar__account__modal-edit-each span{
    font-weight: bold;
    position: absolute;
    right: 0;
    margin-right: 20%;
    opacity: 0.6;
}

.sidebar__account__modal-edit-each-btn{
    padding: 6px 12px;
    font-size: 1.4rem;
    margin: 3% 2%;
    border-bottom: 1px solid #eee;
}

.sidebar__account__modal-edit-each input{
    width: 65% !important;
    margin: 0 2%;
    padding: 6px 12px;
    font-size: 1.4rem;
    border: none;
    border-bottom: 1px solid #eee;
}

.sidebar__account__modal-edit-each button{

    letter-spacing: 2px;    
}

.sidebar__account__modal-email{
    border-bottom: 1px solid #eee;
    margin: 0 2%;
}

.sidebar__account__modal-email p{
    font-size: 1.4rem;
    margin-top: 7px;
    margin-left: 5px;
}
.setting-circle{
    font-size: 1.8rem;
    margin-left: 3rem;
}

.sidebar__account__modal h3{
    margin-left: 5%;
    font-size: 1.4rem;
}

.sidebar__notification__modal{
    margin: 5% 10%;
}

.sidebar__account__modal-notification{
    margin: 4% 0 0 5%;
    border-bottom: 2px solid gray;
    padding-bottom: 3px;
}

.sidebar__account__modal-notification p{
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 5px;
}

.switch-btn{
    margin-left: auto; 
    margin-right: 3rem;
}

.switch-btn-email{
    margin-left: auto; 
    margin-right: 4rem;
}

/*Modal Base*/
.modal-box p{
    font-size: 12.5px;
    text-align: center;
}

.modal__under-card-btn{
    width: 100% !important;
}

.modal__under-card-btn button{
    border: none;
    padding: 5px 5px 5px 10px;
    margin: 0 7px 8px 7px;
    background-color: transparent;
    border-left: 1px solid silver;
    top: 50%;
    bottom: 0;
}
.modal__under-card-btn button:hover{
    opacity: 0.6;
}
.modal-icon{
    font-size:1.2rem;
    padding-left: 5px;
}

.modal-box-card{
    width: 11rem;
    padding: 12px !important;
    display: flex;
    justify-content: center;
    margin: 2rem 10px;
}
.modal-flex-block{
    display: flex;
}

.timeline__info-flex{
    display: flex;
}

.sidebar__account-btn{
    margin-left: 60%;
    padding: 3px 6px;
}

@media (max-width: 480px){
    .modal-flex-block{
        display: block;
        margin: 0 auto;
    }
    .modal-box-card{
        margin: 1rem 0;
    }
    .timeline__info-flex{
        margin: 0.6rem auto;
    }
   
}
.modal__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
}

.backIcon{
    padding: 1.5rem;
    font-size: 1rem;
    border-radius: 50%;
    color: orange;
}


.modal__header>h2{
    flex: 1 1;
    margin-left: 1rem;
    font-size: 1.9rem;
}

.modal__header .MuiButton-root{
    background-color: orange;
    border-radius: 2rem;
    color: white;
    font-size: 1.4rem;
    font-size: 700;
    text-transform: capitalize;
}

.modal__header .MuiButton-root:hover{
   background-color: #FFCC66;
}

.makeStyles-modal-1 div{
    outline: none;
}

.modalImage__imageWrapper{
    border-radius: 2rem;
}
.modalImage__imageWrapper img{
   height: 81.4vh;
   border-radius: 2rem;
}
/* .rounded{
    border-radius: 50% !important;
} */


.modal__close__btn{
    font-size: 2.6rem;
    position: absolute;
    right: 0;
    margin-right: 7%;
    color: black;
}

#overFlowBloc{
    overflow: hidden !important;
}

.modal__close__btn:hover{
    cursor: pointer;
}


.makeStyles-modal-1 div{
    outline: none;
}
.makeStyles-modal-2 div{
    outline: none;
}
.makeStyles-modal-3 div{
    outline: none;
}
.makeStyles-modal-4 div{
    outline: none;
}
.makeStyles-modal-5 div{
    outline: none;
}
.makeStyles-modal-6 div{
    outline: none;
}
.makeStyles-modal-7 div{
    outline: none;
}
.makeStyles-modal-8 div{
    outline: none;
}
.makeStyles-modal-9 div{
    outline: none;
}
.makeStyles-modal-10 div{
    outline: none;
}
.makeStyles-modal-11 div{
    outline: none;
}
.makeStyles-modal-12 div{
    outline: none;
}
.makeStyles-modal-13 div{
    outline: none;
}
.makeStyles-modal-14 div{
    outline: none;
}
.makeStyles-modal-15 div{
    outline: none;
}
.makeStyles-modal-16 div{
    outline: none;
}
.makeStyles-modal-17 div{
    outline: none;
}
.makeStyles-modal-18 div{
    outline: none;
}
.makeStyles-modal-19 div{
    outline: none;
}
.makeStyles-modal-20 div{
    outline: none;
}
.makeStyles-modal-21 div{
    outline: none;
}
.makeStyles-modal-22 div{
    outline: none;
}
.makeStyles-modal-23 div{
    outline: none;
}
.makeStyles-modal-24 div{
    outline: none;
}
.makeStyles-modal-25 div{
    outline: none;
}
.makeStyles-modal-26 div{
    outline: none;
}
.makeStyles-modal-27 div{
    outline: none;
}
.makeStyles-modal-28 div{
    outline: none;
}
.makeStyles-modal-29 div{
    outline: none;
}
.makeStyles-modal-30 div{
    outline: none;
}
.makeStyles-modal-31 div{
    outline: none;
}
.makeStyles-modal-32 div{
    outline: none;
}
.makeStyles-modal-33 div{
    outline: none;
}
.makeStyles-modal-34 div{
    outline: none;
}
.makeStyles-modal-35 div{
    outline: none;
}
.makeStyles-modal-36 div{
    outline: none;
}
.makeStyles-modal-37 div{
    outline: none;
}
.makeStyles-modal-38 div{
    outline: none;
}
.makeStyles-modal-39 div{
    outline: none;
}
.makeStyles-modal-40 div{
    outline: none;
}
.makeStyles-modal-41 div{
    outline: none;
}
.makeStyles-modal-42 div{
    outline: none;
}
.makeStyles-modal-43 div{
    outline: none;
}
.makeStyles-modal-44 div{
    outline: none;
}
.makeStyles-modal-45 div{
    outline: none;
}
.makeStyles-modal-46 div{
    outline: none;
}
.makeStyles-modal-47 div{
    outline: none;
}
.makeStyles-modal-48 div{
    outline: none;
}
.makeStyles-modal-49 div{
    outline: none;
}
.makeStyles-modal-51 div{
    outline: none;
}
.makeStyles-modal-52 div{
    outline: none;
}
.makeStyles-modal-53 div{
    outline: none;
}
.makeStyles-modal-54 div{
    outline: none;
}
.makeStyles-modal-55 div{
    outline: none;
}
.makeStyles-modal-56 div{
    outline: none;
}
.makeStyles-modal-57 div{
    outline: none;
}
.makeStyles-modal-58 div{
    outline: none;
}
.makeStyles-modal-59 div{
    outline: none;
}
.makeStyles-modal-60 div{
    outline: none;
}
.makeStyles-modal-61 div{
    outline: none;
}
.makeStyles-modal-62 div{
    outline: none;
}
.makeStyles-modal-63 div{
    outline: none;
}
.makeStyles-modal-64 div{
    outline: none;
}
.makeStyles-modal-65 div{
    outline: none;
}
.makeStyles-modal-66 div{
    outline: none;
}
.makeStyles-modal-67 div{
    outline: none;
}
.makeStyles-modal-68 div{
    outline: none;
}
.makeStyles-modal-69 div{
    outline: none;
}
.makeStyles-modal-70 div{
    outline: none;
}
.makeStyles-modal-71 div{
    outline: none;
}
.makeStyles-modal-72 div{
    outline: none;
}
.makeStyles-modal-73 div{
    outline: none;
}
.makeStyles-modal-74 div{
    outline: none;
}
.makeStyles-modal-75 div{
    outline: none;
}
.makeStyles-modal-76 div{
    outline: none;
}
.makeStyles-modal-77 div{
    outline: none;
}
.makeStyles-modal-78 div{
    outline: none;
}
.makeStyles-modal-79 div{
    outline: none;
}
.makeStyles-modal-80 div{
    outline: none;
}
.makeStyles-modal-81 div{
    outline: none;
}
.makeStyles-modal-82 div{
    outline: none;
}
.makeStyles-modal-83 div{
    outline: none;
}
.makeStyles-modal-84 div{
    outline: none;
}
.makeStyles-modal-85 div{
    outline: none;
}
.makeStyles-modal-86 div{
    outline: none;
}
.makeStyles-modal-87 div{
    outline: none;
}
.makeStyles-modal-88 div{
    outline: none;
}
.makeStyles-modal-89 div{
    outline: none;
}
.makeStyles-modal-90 div{
    outline: none;
}
.makeStyles-modal-91 div{
    outline: none;
}
.makeStyles-modal-92 div{
    outline: none;
}
.makeStyles-modal-93 div{
    outline: none;
}
.makeStyles-modal-94 div{
    outline: none;
}
.makeStyles-modal-95 div{
    outline: none;
}
.makeStyles-modal-96 div{
    outline: none;
}
.makeStyles-modal-97 div{
    outline: none;
}
.makeStyles-modal-98 div{
    outline: none;
}
.makeStyles-modal-99 div{
    outline: none;
}
.makeStyles-modal-100 div{
    outline: none;
}
.jobBox{
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    overflow-y: hidden;
}
.jobBox__input textarea{
    overflow-y: scroll !important;
    height: 100px !important;
    resize: none !important;
    padding: 3rem 0 3% 0;
    max-width: 100%;
    font-size:1.6rem;
    border: none;
    outline: none;
    font-family: inherit !important;
    font-weight: 499 !important;
    color: inherit !important;
    width: 80%;
    border-bottom: 2px solid gray;
    margin-bottom: 2%;
}
.jobBox__input input{
    width: 80%;
    flex: 1 1;
    border: none;
    font-size: 1.6rem;
    outline: none;
    padding: 1rem 0 2rem 0;
    border-bottom: 2px solid gray;
    margin-bottom: 3%;

}

.jobBox__input select{
    width: 80%;
    margin-top: 1rem;
    border: none;
    border-bottom: 2px solid black;
}
.jobBox__ava{
    padding: 2rem;
    display: flex;
}

.jobBox__ava p{
    font-size: 2.5rem;
    padding-left: 5%;
}

.jobBox__input-image{
    position: relative;
    margin-bottom: 1rem;
}

.jobBox__input-image::after{
    display: block;
    content: '';
    padding-top: 56.25%;
}

.jobBox__input-image>img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;

    opacity: 1; 
    transition: opacity 1.5s ease-out;
}

.cancelIcon{
    font-size: 3.5rem !important;
    position: absolute;
    top: .5rem;
    left: .5rem;
    z-index: 2;
    cursor: pointer !important;
}

.cancelIcon:hover{
    opacity: 0.6;    
}

.editImage{
    background-color: black !important;
    border: none !important;
    color: white !important;
    font-weight: 500 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 3rem;
    position: absolute !important;
    right: .8rem;
    top: .7rem;
    font-size: 1.6rem !important;
}

.editImage:hover{
    opacity: 0.6;
}


.jobBox__input-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1 1;
    margin-left: -.8rem;
}

.jobBox__input-icons>div{
    padding: .6rem;
    border-radius: 50%;
    cursor: pointer;
}

.jobBox__input-icons>div:nth-child(3){
    transform: rotate(90deg);
}

.jobBox__input-icons>div:hover{
    background-color: #f5f8fa;
}

.jobBox__input-icons .MuiSvgIcon-root{
    font-size: 2rem;
    color: #ffc300;
    cursor: pointer;
}


.jobBox__button{
    background-color: #ffc300 !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 4rem;
    width: 12rem;
    margin-top: 2rem !important;  
    font-size: 1.4rem !important;
    margin-bottom: 5% !important;
    margin-left: -5rem;
}


.jobPost__select{
    font-size: 1.6rem;
    opacity: 0.7;
    padding: 5px 0;
    margin-bottom: 10px;
}


.jobPosting__box__home{
    margin-top: 4%;
}

.youtube__click__info{
    font-size: 2rem;
    color: gray;
}

.youtube__info{
    border: none;
    background-color: transparent;
    z-index: 100;
}

.youtube__info__div{
    width: 100%;
    padding: 2% 0;
}

.youtube__details li{
    padding-top: 3%;
    font-size: 1.5rem;
    letter-spacing: 1px;
}

.youtube__details ol{
    padding-left: 20%;
}

.youtube__details strong{
    color: red;
}
.salary-input{
    width: 40% !important;

}
.salary-input input{
    height: 5px !important;
    width: 5px !important;
    margin: 1rem 2rem 0 0 !important;
    padding: 10px;
}

.salary-input p{
    font-size: 1.8rem;
    margin-top: 1.2rem;
    margin-right: 8px;
}

@media screen and (max-width: 500px){
    .jobBox{
        margin-bottom: 10%;
    }
    .youtube__details img{
        width: 440px !important;
    }

    .youtube__details ol{
        padding-left: 10%;
    }

}
.bottomNav{
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;    
    z-index: 1000;
    background: white;
    padding: 1rem 0;   
}

.bottomNav>nav{
    flex: 1 1;
    display: flex;
    flex-direction: row;    
}

.bottomNav>nav>a{
    flex: 1 1;
    text-align: center;
}

.bottomNav .MuiSvgIcon-root {
    font-size: 2.5rem;
    color: gray;
}

.bottomNav__active .MuiSvgIcon-root{
    color: #ffc300 !important;
}

@media screen and (min-width: 501px){
    .bottomNav{
        display: none;
    }
}
.popover-content{
    z-index: 1000;
    position: absolute;
    left: 0;
    margin-left: 66%;
}

@media (max-width: 480px){
    .popover-content{
        margin-left: 36%;
    }
}
.tabbarMenu .MuiTabs-root{
    background: white;
    text-transform: unset !important;
}

.tabbarMenu .MuiSvgIcon-root{
    font-size: 2.3rem;
}
.tabbarMenu .MuiTab-fullWidth:hover {
    background: #FFCC66;
    opacity: 0.3;
}

.tabbarMenu .MuiPaper-elevation4{
    box-shadow: none;
}

.tabbarMenu .MuiTab-textColorPrimary.Mui-selected{
    color: orange;
    font-weight: bold;
}

.tabbarMenu .PrivateTabIndicator-root{
    background-color: orange;
}

.tabbarMenu .MuiTabs-indicator {
    background-color: orange;
}

.tabbarMenu .react-swipeable-view-container .MuiBox-root-6{
    padding: 1rem;
}

.tabbarMenu .MuiBox-root {
    padding: 0rem;
    overflow: hidden;
}

.tabbarMenu .MuiTab-wrapper {
    font-size: 1.5rem;
    text-transform: none;
}

.btn__save{
    color: white !important;
    background-color: #FFC300 !important;
    margin: 2% !important;
    font-size: 1.4rem !important;
    padding: 5px 15px !important; 
    font-weight: bold !important;
}

@media screen and (max-width: 320px) {
    .tabbarMenu .MuiTab-wrapper{
        font-size: 1rem;
    }
    .tabbarMenu .react-swipeable-view-container .MuiBox-root-6{
        padding: 0.2rem;
    }
}
.active__navigation{
    width: 100% !important;
    margin-top: 4%;
    z-index: 1000;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.active__navigation::-webkit-scrollbar {
    display: none; 
}


.active__navigation ul{
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-right: 2%;

}
.active__navigation li{
    font-size: 1.3rem;
    border-radius: 20px;
    padding: 8px 16px;
    margin: 0 !important;

}

.active__navigation span:hover{
    color: #FFCC66 !important;
}

.active{
    background-color: #FFC300 !important;
    border-radius: 20px;
    padding: 10px 35px;
    color: white;
    margin: 0 !important;
    font-weight: bold;
}
/*UnAuth Top bar*/
.unAuth__topBar{
    width: 100% !important;
    height: 6rem;

}
.unAuth__topBar__bottom{
    border-bottom: 1px solid lightgray;
    width: 100%;
    margin-bottom: 1rem;
}

.unAuth__topBar__Auth{
    margin: 1rem 3rem 0 auto;

}
.unAuth__topBar__Auth button{
    padding: 7px 20px;
    color: white;
    background-color: #FFC300;
    border:  orange 1px solid;
}

.unAuth__topBar__img img{
    width: 200px;
    margin: 10px 0 6px 0;
}

.unAuth__topBar__content button{
    margin-top: 1rem;
    padding: 20px 0;
    border: none;
    width: 100%;
    background-color: transparent;
    font-weight: bold;
    font-size: 1.4rem;
    color: black;
}

.unAuth__topBar__content button:hover{
    background-color: whitesmoke;
}

.menuBar__btn{
    background-color: transparent;
    border: none;
    margin-right: 5%;
    padding: 2px 12px;
    border-radius: 50%;
    margin-top: 2%;
}

.menuBar__btn:hover{
    background-color: lightgray;
}

.navigation{
    width: 100%;
    height: 4rem;
}


.topBar__menu{
    color: black;
}



@media (max-width: 320px){

    .active__navigation li{
        font-size: 1rem;

    }

    .active__navigation ul{
        margin-left: 17rem;
    }

    .active{
        font-size: 1.2rem;
        padding: 8px 15px;
    }
    .unAuth__topBar__img img{
        width: 140px;
        margin: 10px 0 10px -5px;
    }
    .unAuth__topBar__Auth button{
        padding: 4px 10px;
    }

    .active__navigation__search ul{
        margin-left: 22rem;
    }

}

@media (min-width: 321px) and (max-width: 480px){
    .active__navigation__timeline li{
        font-size: 1rem;
    }
    .active__navigation__timeline{
        margin-top: -2.4rem;
    }
    .menuBar__btn{
        margin-right: 1%;
    }

    .unAuth__topBar__img img{
        width: 140px;
    }
    .unAuth__topBar__Auth button{
        padding: 4px 20px;
    }

    .active__navigation ul{
        margin-left: 15rem;
    }
    .active__navigation__search ul{
        margin-left: 22rem !important;
    }
    .active{
        padding: 8px 15px;
    }

}

@media (max-width:700px){
    .active__navigation ul{
        margin-left: 15rem;
    }
}
.mobile__top__nav{
    display: none;
}

.company__dashboard-nav-mobile{
    width: 100%;
    display: none;
}



@media (max-width: 500px){
    .mobile__top__nav__img img{
        width: 180px;
        margin: 10px 0 0 2px;
        padding: 0 !important;
    }
    
    .mobile__top__nav{
        width: 100%;
        height: 5rem;
        margin-bottom: 10%;
        border-bottom: 1px solid lightgray;
        display: block !important;
        background-color: white;
    }
    
    .mobile__btn{
        margin: 3% 5% 0 12%;
    }

    
    .mobile__btn button{
        padding: 6px 10px;
        font-weight: bold;
        font-size: 1.2rem;
        background-color: orange;
        color:white;
        border: none;
        border-radius: 30px;
    }

    .mobile__btn button:hover{
        background-color: orange;
        color: white;
    }
    
    .mobile__nav__icon{
        transform: scale(2);
    
    }

    .menuBarInfo__btn{
        margin: 15px 5px 5px 5px !important;
        height: 3rem;
        background-color:transparent;
        border-radius: 50%;
        border: none;
    }

    .menuBarInfo__btn:hover{
        background-color: lightgray;
    }
    .company__dashboard-nav-mobile-item{
        display: flex;
        margin-bottom: 3%;
    }
    .company__dashboard-nav-mobile{
        display: block !important;
    }
    .sidenavigation-menu-active-mobile{
        background: white;
        opacity: 1;
        border-bottom: 4px solid #432bfb;
        color: #432bfb ;
        padding: 1.2rem 0 1.2rem 1.2rem;
    }
    .sidenavigation-menu-mobile{
        padding: 1.2rem 0 1.2rem 1.2rem;
        display: block;
        color: gray;
        font-weight: bolder;
        opacity: .8;
        font-size:2rem !important;
        margin: 0 1rem;
    }

}
.footerIcon_wrapper{
    flex: 1 1;
    font-size: 1.4rem;
    display: flex;
    font-weight: 400;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: gray;
    cursor: pointer;
}

.footerIcon_wrapper .MuiSvgIcon-root {
    font-size: 1.8rem;
    color: #ffc300;
    border-radius: 50%;
}

.footerIcon_wrapper .MuiSvgIcon-root:hover{
    background-color: var(--copipel-background);
}

.footerIcon__counter{
    margin-left: 1.5rem;
}

.liked .MuiSvgIcon-root{
    color: rgb(224, 36, 94) !important;
}

.whoLikes__show button{
    margin: 0 auto;
    display: block;
    padding: 4px 8px;
    background-color: orange;
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 4px;
}

.whoLikes__show{
    margin-left: 1rem;
}


.userItemFollow--user__item{
    padding: 1rem ;
    display: flex ;
    flex-direction: row ;
    align-items: center ;
    cursor: pointer ;
}

.userItemFollow--user__item:hover{
    background: var(--twittie-background-2) ;
}

.userItemFollow--user__details{
    margin-left: 1rem ;
    flex: 1 1;
    display: flex ;
    flex-direction: column ;
    justify-content: center ;
}

.userItemFollow--user__details>span{
    font-size: 1.1rem ;
    color: black;
}

.userItemFollow--user__item .MuiButton-root{
    border: 1px solid orange;
    border-radius: 2rem;
    color: #ffc300;
    font-weight: 600;
    text-transform: unset;
    font-size: 1.2rem;
    height: 2.8rem;
    width: 7rem;
}

.userItemFollow--user__item  .MuiAvatar-img{
    width: 5rem;
    height: 5rem;
}

.userItemFollow--user__item .MuiAvatar-root{
    margin-right: 1.5rem;
}

.user__item{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.nocursor{
    cursor: unset !important;
}

.user__details{
    margin-left: 1rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
}

.user__details>span{
    font-size: 1.1rem;
    color: #ff5733;
}

.user__item .MuiSvgIcon-root{
    padding: 1rem;
    color: orange;
}

.post__front__img{
    width: 300px !important;
    height: 240px !important;
    border-radius: 20px;
}

.post__front__img__div{
    margin-right: 5% !important;
}

.post__headerDescription p{
    font-size: 2rem;
    font-weight: 600;
}

.post__enter__top{
    padding-top: 2%;
}

.post__enter__top p {
    font-weight: 650;
    font-size: 1.2em;
}

.post__enter__top i{
    color: orange;
    font-size: 20px;
    padding-top: 2px;
}

.post__enter__top__text{
    font-size: 14px;
}

.each__post__enter__top{
    display: flex;
    padding-top: 1%;
}

.each__post__enter__top p{
    padding-left: 2%;
}
.post__enter{
    font-size: 2rem !important;
    padding-bottom: 2%;
}
.post__headerText h3{
    margin-top: 1.5rem !important;
}

.tabPost__save__post-front{
    margin: -3.4rem 0 0 85%;
}

.tabPost__save__post-front button{
    background-color: transparent;
    border: none;
}

.isDone__btn__post{
    border: none;
    padding: 8px;
    background-color: transparent;
    position: absolute;
    right: 0;
    margin: -2rem 2rem 0 0 ;
    font-size:2rem;
}

.modal__alert__meg{
    padding: 5% 25%;
}

.modal__alert__meg h2{
    font-size: 2rem;
}

.modal__alert__meg p{
    padding: 4% 0 0 2%;
    font-size: 1.2rem;
    color: red;
}

.isDone__btn button{
    width: 16rem;
    margin: 2rem 33%;
    padding: 5px 10px;
    font-weight: 550;
    border-radius: 4px;
}

.isDone__btn__complete{
    background-color: green;
    color: white;
}

.post__expandList input{
    width: 0.1px;
    border: none;
    position: absolute;

}
.post__expandList button{
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: left;
}

.post__expandList li{
    color: black;
    font-size: 1.4rem;
}

.post-icon{
    font-size: 1.6rem !important;
    padding-right: 1.5rem;
    color: black;
}

.post-success-icon{
    color: green;
}

.kindJob__text{
    font-size: 1.5rem !important;
    color: white;
    margin: 2% 0;
}

.post__headerDescription span{
    background-color: gray;
    padding: 6px 12px;
    border-radius: 5px;
}
.post__headerDescription{
    margin-left: 10%;
    margin-top: 1.5rem;
}

.d-flex{
    display: flex;
}

.padding-left-3{
    padding-left: 3%;
}

.post__reqSkill{
    margin-top: 3%;
}

.post__skills p{
    font-size: 1.6rem;
    opacity: 0.8;
    padding: 4px 8px;
    background-color: whitesmoke;
    border: 1px solid gray;
    margin-right: 2%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    font-weight: bold;
    height: 2.8rem;
}

.text__deco1{
    font-weight: 500 !important;
}

.balloon3-right {
    position: relative;
    display: inline-block;
    margin: 1.5em 15px 1.5em 0;
    padding: 0 2px;
    width: 160px;
    height: 30px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    background: #ffcc75;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .balloon3-right:before {
    content: "";
    position: absolute;
    top: 50%;
    right: -24px;
    margin-top: -15px;
    border: 15px solid transparent;
    border-left: 15px solid #ffcc75;
    z-index: 0;
  }

.post__wanted__job{
    width: 100%;
}
.post__wanted__job p{
    margin: 2rem 0 0 3rem !important;
    width: 100%;
}

.width-100{
    width: 100%;
}

.post__website{
    font-size: 1.6rem !important;
    font-weight: 400;
    padding-top: 3%;
    opacity: 0.8;
}

.post__ExpandIcon button{
    background-color: transparent;
    border: none;
    padding: 1rem;
    border-radius: 50%;
}

.post__ExpandIcon button:hover{
    background-color: lightgray;
}

.expandIcon{
    font-size: 2rem !important;
    color: black;
}

.post-icon{
    font-size: 1.2rem
}
.post__apply-btn{
    display: table;
    margin: 0 auto;
    padding: 7px 30px;
    background: #ffc300;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
}

.hire__section{
    margin: 2% 0 2% 1rem;
    font-size: 1.4rem !important;
}

.under__recruite__mark{
    color: green;
}

.done__recruite__mark{
    color: red;
}

.recruite__mark{
    font-size: 1.4rem;
    margin-top: 1.5rem;
    margin-left: 85%;
}

.recruite__mark-icon{
    padding-right: 5px;
}
.post__keyword ul{
    display: flex;
    margin-left: 5rem;
}

.post__keyword li{
    font-size: 1.3rem;
    border: none;
    color: black;
    border-left: 2px solid black;
    border-right: 2px solid black;
    margin-right: 1.5rem;
    padding: 0 1.5rem;
}

.post__keyword-link:hover{
    opacity: 0.6;
}

.post__keyword-front{
    margin-top: 1rem;
}

.mobile-flex{
    display: flex;
}

.qualification__short p{
    color: black;
    font-size: 1.3rem;
    margin-top: 2rem;
}

@media (min-width: 321px) and (max-width: 480px){
    .mobile__img{
        display: block !important;
    }
    .tabPost__save__post{
        padding-left: 54%;
    }
    .post__enter{
        font-size: 2rem !important;
    }
    .kindJob__text{
        font-size: 1.2rem !important;
    }
    .post__enter__top p{
        font-size: 1.4rem;
    }
    .post__skills p{
        font-size: 1.3rem;
    }
    .recruite__mark{
        margin-left: 78%;
        margin-bottom: 3rem;
    }
    .post__keyword-status{
        margin-left: 1rem;
    }
    .post__keyword ul{
        margin-left: 1rem;
        margin-top: 2rem;
    }
    .post__keyword li{
        padding: 0 5px;
        margin-right: 0.5rem;
    }
    .post__headerText{
        margin-top: -4rem !important;
    }
    .jobs_timeLine-btn button{
        padding: 4px 8px;
        font-size: 0.8rem;
    }
    .post__keyword li{
        font-size: 1.1rem;
    }
}

@media (max-width: 480px){
    .post__body{
        margin: 5rem 0 0 1rem;
    }
    .post__header{
        margin-bottom: 2rem;
    }
    .post__skills p{
        padding: 3px 6px;
        background-color: whitesmoke;
        border: 1px solid gray;
        margin-right: 2.5%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        font-weight: bold;
        height: 2.5rem;
    }
}
.job__post__img{
    width: 450px !important;
    height: 350px !important;
}

.d-display{
    display: flex;
}

.job__header__body{
    padding-left: 3% !important;
}

.job__header__body p{
    padding: 10% 0 0 20% !important;
    font-size: 1.4rem !important;
}


.job__post__detail p{
    font-size: 1.4rem !important;
}
.statusPost__body table{
    width: 100%;
    border-spacing: 0;
    padding: 0 10% 3% 10%;
    font-size: 1.3rem !important;
}

.statusPost__body table th{
    border-bottom: solid 2px #fb5144;
    padding: 10px 0;
    width: 30%;
}

.statusPost__body table td{
    border-bottom: solid 2px #ddd;
    text-align: center;
    padding: 10px 0;
    width: 70%;
}

.statusPost__body h2{
    padding-left: 5%;
    padding-top: 5%;
    width: 100%;
}

.job__post__icon{
    color: orange;
}

.job__post__text{
    white-space: pre-line;
    padding: 2% 0 3% 8% !important;
}

.statusPost__body h1{
    font-size: 4rem;
}

.statusPost__body p{
    padding-top: 1%;
}

.opacity-6{
    opacity: 0.6;
}

.statusPost__body--message p{
    font-size: 1.4rem;
}

.statusPost__body--message h5{
    font-size:2.5rem;
}

.video-responsive {
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin: 5% 0 -20% 20%;
  }
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 60%;
    width: 70%;
    position: absolute;
}

.statusPost__body span{
    background-color: gray;
    padding: 6px 12px;
}

.website__post{
    font-size: 1.4rem;
    text-decoration: underline;
}

.status__post__top i{
    color: orange;
    font-size: 2rem;
}

.status__post__top p{
    font-size: 1.6rem;
    font-weight: 800;
}

.status__post__watedJob p{
    font-size: 2rem;
    padding: 30% 0 0 5%;
    font-weight: 700;
}

.status__post__intro p{
    font-size: 1.4rem;
    padding-left: 5%;
    white-space: pre-line;
}

.event__post__body h1{
    font-size: 3rem;
}

.event__post__date h2{
    font-size: 3rem;
    opacity: 0.6;
    width: 30% !important;

}

.event__post__date i{
    color: orange;
    font-size: 2.2rem;
}
.d-block{
    display: block !important;
}
.event__date__head{
    font-size: 3.5rem !important;
}

.event__time__place h2{
    width: 100% !important;
    font-size: 2.3rem !important;
}

.event__time__place{
    padding-top: 4%;
}

.event__details h2{
    font-size: 2.3rem;
}

.event__details p{
    padding-left: 5%;
    font-size: 1.5rem;
    white-space: pre-line;
}

.event__details{
    padding-bottom: 5%;
    padding-top: 6%;
}

.freelance__details h2{
    font-size: 2.3rem;
}

.freelance__details p{
    padding-left: 5%;
    font-size: 1.5rem;
    white-space: pre-line;
}
.freelance__money i{
    color:orange;
}

.freelance__money p{
    font-size: 2rem;
}


.padding-left-5{
    padding-left: 5%;
}
.post-border{
    border: 1px solid silver;
    margin-top: 1rem;
}
.post {
    display: flex;
    align-items: flex-start;
    border-radius: 20px;
    padding-bottom: 1rem;
    margin-bottom:3% !important;
}

.post:hover{
    background: var(--copipel-background-2);
}

.post__body{
    flex: 1 1;
    padding: 1rem;
}

.post__body> img{
    border-radius: 2rem;
    width: 100%;
    height: auto;
    cursor: pointer;    
}
.post__header{
    border-bottom: 1px solid lightgray;
}
.post__headerDescription {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.post__headerDescription>p{
    line-height: 2.1rem;
}

.post__headerText{
    display: flex;
    flex-direction: row;
}

.post__headerText>h3{
    flex: 1 1;
    font-size: 1.5rem;
    margin: .8rem 0 1rem;
}

.post__badge{
    font-size: 1.8rem !important;
    color: #ff5733 !important;
}

.post__headerSpecial{
    font-weight: 600;
    font-size: 1.2rem;
    color: gray;
}

.post__avatar{
    margin: 1rem 1rem 1rem 1.5rem;
}

.post__footer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.post__headerExpandIcon{
    padding: 1rem;
    border-radius: 50%;
}

.post__headerExpandIcon:hover{
    background: var(--copipel-background-2);
    color: orange;
}

.post__expandList{
    list-style: none;
    padding: 0 0 0 0;
    border-radius: 2rem;
}

.post__expandList li{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;   
    padding: 1.2rem; 
    background: white;
    cursor: pointer;
    min-width: 20rem;
}

.post__expandList li:first-child{
    border-radius: 1rem 1rem 0 0 !important;
}

.post__expandList li:last-child{
    border-radius: 0rem 0rem 1rem 1rem !important;
}

.post__expandList li:hover{
    background: #f5f8fa !important;
}

.post__expandList li>h3{
    font-weight: 400 !important;
}

.post__expandList>li>div{
    margin-right: 1rem;
}

.post__expandList>li>div .MuiSvgIcon-root{
    font-size: 1.8rem;
    padding: .5rem 0rem 0rem 0rem;
}

.tabPost__save__post-status{
    padding-left: 90%;
    margin: -4rem 32% 0 0;
}
.tabPost__save__post-status button{
    border: none;
    background-color: transparent;
}

.delete{
    color: red;
}

.tweetBox__input-actions .MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.post__statusPostHeader{
    flex: 1 1;
    margin-top: .5rem;
    font-size: 1.4rem;
} 

.bottomWhited{
    border-bottom: none;
    padding-bottom: 0;
}

.post__enter{
    white-space: pre-line !important;
}

.status__post__skills p{
    margin: 2rem 4% 0 0 ;
}

.statusPost__body--date{
    margin-top: 5%;
}

.post__keyword-status{
    margin-top: 3rem;
}

@media (max-width: 320px){

}

@media (min-width: 321px) and (max-width: 480px){

    .statusPost__body{
        padding-left: 3%;
    }
    .statusPost__body--message h1{
        font-size: 2.8rem;
        padding: 5px 0;
    }
    .post__footer{
        padding-left: 8%;
    }
    .event__post__body h1{
        font-size: 2.8rem;
    }
    .event__post__date h2{
        font-size: 2.5rem !important;
    }
    .event__time__place h2{
        font-size: 2rem !important;
        padding-left: 6rem;
    }
    .event__details h2{
        font-size: 2rem ;
    }
    .freelance__post__body h1{
        font-size: 2.8rem;
        padding: 5px 0;
    }
    .freelance__details h2{
        font-size: 2rem;
    }
    .freelance__post__body{
        padding-bottom: 10%;
    }
    .tabPost__save__post-status{
        margin-top: -2.6rem;
    }
}


.footerIcon_wrapper{
    margin: 3% 5% 2% 0;
    width: 100%;
}

.bookmark-icon{
    font-size: 2.5rem;
    color: black;
}
.widgets__trendsContainer{
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0 !important;
}

.widgets__trendsContainer>li{
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-bottom: 1px solid #e6ecf0;
}

.widgets__trendsContainer>li{
    border-bottom:none;
}

.widgets__trendsContainer li div{
    padding: 0.2rem 0.2rem;
    margin-left: -.2rem;
}

.widgets__trendsContainer li div.popular{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    color: lightgray;
}

.widgets__trendsContainer li div.hashtag{
    font-size: 1.5rem;
    font-weight: 650;
}

.widgets__trendsContainer li div.tweetNumber{
    font-size: 1.3rem;
    color: lightgray;
}

.widgets__widgetContainer {
    margin: .7rem 1rem 1.5rem 2rem;
    padding: 2rem;
    background-color: #f5f8fa;
    border-radius: 2rem;
}

@media (max-width: 700px){
    .widgets__widgetContainer{
        display: none;
    }
}
.widget__post__side img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 1rem;

}

.widget__card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 10px;
    margin: 5% 0 5% 2%!important;
}

.widget__post{
    padding-top: 3%;
}

.d-block{
    display: block !important;
}

.widget__follow span{
    padding-bottom: 5%;
}

.display-none{
    display: none !important;
}

.wiget__copy__btn input{
    width: 0.1px;
    border: none;
}

.wiget__copy__btn button{
    width: 100%;
    margin-bottom: 6%;
    padding: 3px 6px;
}

.widget__sns li{
    margin-right: 2%;
}

.mail__widget__wrapper{
    margin-bottom: 2%;
    width: 75%;
}
.follow__widget__wrapper{
    width: 85% !important;
}

.profile__jump__wrapper{
    color: #ffc300;
}


.follow__widget__wrapper>div{
    padding: .5rem 1.5rem;
    border: 1px solid orange;
    border-radius: 2rem;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: -2rem;
}

.follow__widget__wrapper:hover{
    cursor: pointer;
}
.follow__wrapper{
    background-color: #ffc300;
    color: white;
}

.followed__wrapper{
    background: transparent;
    color: #ffc300;
}

@media screen and (max-width: 500px){
    .widget__post{
        display: none;
    }
}
.mail__btn i{
    font-size: 1.5rem;
    padding: 0.4rem;
    color: #ffc300;
}

.mail__btn__link{
    color: #ffc300 !important;
    text-align: center;
}


.ststusInput__icon{
    font-size: 2rem;
}
/*ADD ALT */
.alt__wrapper{
    display: flex;
    flex-flow: column;
    height: 100%
}

.alt__wrapper .alt__imageContainer{
    flex: 1 1 auto;
}

.alt__imageContainer img {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 27em;
}

.alt__addDescription {
    padding-top: 2rem;
}

.alt__addDescription h2{
    color: var(--main-color);
    margin-left: 1rem;
}

.alt__addDescription textarea{
    margin-left: 1rem;
    border: none;
    width: 100%;
    font-size: 1.4rem;
    outline: none;
    padding: 0;
}


/*CROP PHOTO*/
.cropImage__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cropImage__wrapper:first-child{
    flex: 1 1;
}

.cropImage__imageSelection{
    width: 100%;
    height: 100%;
}

.blued {
    color: var(--main-color);
}

.cropImage__wrapper .ReactCrop__crop-selection{
    border: 4px solid var(--main-color);
    border-radius: 2rem;
}

.cropImage__wrapper .ReactCrop__image{
    width: 100%;
    height: auto;
    /* height: 35rem; */
    object-fit: contain;
    margin: 0 auto !important;
}

.cropImage__changeAspectRatio-actions{
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0 0;
}

.cropImage__changeAspectRatio-actions>div{
    flex: 1 1;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton{
    display: flex;
    flex-direction: row;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton>div{
    flex: 1 1;
    text-align: center;
}

.cropImage__slider{
    padding-left: 3em;
}

.cropImage__slider .MuiSlider-root {
    padding: unset!important;
    color: var(--main-color);
}


.conversationInfo{
    flex: 3 1;
    display: flex;
    flex-direction: column;
    border-right:1px solid var(--copipel-background);
}

.conversationInfo__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--copipel-background);
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.conversationInfo__header>h2{
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
    margin-right:2rem
}

.conversationInfo__header-back{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.conversationInfo__header-back:hover{
    background: #f5f8fa;
}

.conversationInfo__header .MuiSvgIcon-root {
    font-size: 2.5rem !important;
    color: #ffc300;
}

.conversationInfo .MuiButton-root{
    text-transform: unset;
    font-size: 1.3rem;
    color: #ffc300 !important;
    border-radius: 2rem;
    border: 1px solid orange;
    padding: 0rem 1rem ;
    max-height: 2.7rem;
}

.conversationInfo .MuiButton-root:hover{
    background: #e8f5fe;
}

.userActions{
    padding: 0;
    list-style-type: none;
}

.userActions>li{
    font-size: 1.3rem !important;
    text-align: center;
    font-weight: 450;
    padding: 1rem;
    color: orange;
    cursor: pointer;
    border-top: 1px solid var(--copipel-background);
}


.red{
    color: rgb(224, 36, 94) !important;
}

.red:hover{
    background: #e0245e0d !important;
}
.sidebarChatOption{
    display: flex;
    /* flex-direction: row; */
    padding: 1.5rem;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
}

a{
    text-decoration: none!important;
    color: black;
}

.sidebarChatOption:hover{
    background-color: var(--twittie-background-2)
}

.sidebarChatOption__info > h2 {
    font-size: 1.5rem;
}

.sidebarChatOption__info{
    font-size: 1.5rem;
    flex: 1 1;
    margin-left: 1.5rem;
}

.sidebarChatOption__info span{
    color: var(--twittie-light);
    font-weight: 400;
    margin-left: .5rem;
}
.sidebarChatOption__info >p{
    color: var(--twittie-light)
}

.sidebarChatOption__date{
    font-size: 1.5rem;    
    padding-right: .1rem;
    color: var(--twittie-light)
}

.sidebarChatOption .MuiAvatar-root{
    width: 5rem;
    height: 5rem;
}


.active_chat{
    border-right: 2px solid var(--twittie-color);
}
.sidebarChat{
    flex: 2 1;
    border-right: 1px solid var(--twittie-background);
}

.noned{
    display: none;
}

.sidebarChat__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.sidebarChat__header>h2{
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
}

.sidebarChat .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
    margin-left: 1rem;
    margin-right: .1rem;
    cursor: pointer;

}



.sidebarChat__search{
    display: flex;
    align-items: center;
    height: 3.9rem;
    padding: .8rem;
    border-bottom: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    border-radius: 2rem;
    background-color: var(--twittie-background);
    border: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer.pressed{
    border: 1px solid var(--twittie-color) !important;
    background-color: white !important;
}

.sidebarChat__searchContainer >input{
    border: transparent;
    line-height: 2.8rem;
    outline: none;
    width: calc(100% - 7rem);
    background: var(--twittie-background);
}

.sidebarChat__searchContainer >input.inputPressed{
    background: white !important;    
}

.sidebarChat__searchContainer > .MuiSvgIcon-root {
    color: var(--twittie-light);
    padding: .5rem;
    cursor: pointer;
}

.sidebarChat__searchContainer.pressed > .MuiSvgIcon-root{
    color: var(--twittie-color);
}

.sidebarChat__chats{
    background-color: white;
    overflow: hidden;
    flex: 1 1;
}


.sidebarChat__noExist img{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.sidebarChat__noExist h2{
    text-align: center;
    margin: 10% 0 2rem 0;
    font-size: 2rem;
}

.how__toStart button{
    background: transparent;
    border: none;
}
.how__toStart p{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2px;
}
.chat__question-icon{
    font-size: 2rem;
}

.how__toStart{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}


@media screen and (max-width: 768px){
    .sidebarChat__noExist img{
        width: 70%;
    }
    .how__toStart{
        width: 35%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px){
    .sidebarChat__noExist img{
        width: 50%;
    }
    .how__toStart p{
        font-size: 2rem;
    }
}


.editProfile__theme{
    background: white;
}

.editProfile__theme-photoWrapper{
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    margin-left: 35%;
    background: whitesmoke;
}

.editProfile .MuiSvgIcon-root{
    font-size: 2.75rem !important;
    color: white;
}


.editProfile__theme-photoWrapper>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
}

.editProfile__theme-photoWrapper>div{
    position: absolute;
    top: 15%;
    left: 47%;
    padding: 1rem;
    border-radius: 50%;
    transform: translate(-45%, -45%);
    cursor: pointer;
}

.editProfile__theme-photoWrapper>div:hover{
    background: whitesmoke;
    opacity: 0.6;
}

.editProfile form{
    margin-top: 3.5rem;
    width: 100%;
}

.editProfile .MuiFormControl-root{
    width: 100%;
}

.editProfile .MuiFilledInput-input{
    font-size: 1.5rem
}

.editProfile .MuiInputLabel-filled.MuiInputLabel-shrink{
    padding: 0 2.6rem;
}

.editProfile .MuiInputLabel-filled{
    font-size: 1.6rem;
    font-weight: 700;
    padding: 0 2rem;
}

.editProfile .MuiFormLabel-root.Mui-focused{
    color: #ffc300 !important;
}

.editProfile .MuiFilledInput-root{
    background-color: whitesmoke !important;
    padding: 0 2rem;
}

.editProfile .MuiFormControl-root:nth-child(3){
    border-radius: 0 0 2rem 2rem;
}

.editProfile .MuiFilledInput-underline:before{
    border-bottom: none 
}

.editProfile .MuiFilledInput-underline:after{
    border-bottom: 3px solid orange;
}
.text__box label{
    font-size: 18px;
}
.text__box textarea{
    font-size: 20px;
    padding-top: 7%;
}


.editProfile__attention{
    font-size: 16px;
    padding-top: 1rem;
}


@media screen and (max-width: 500px){
    .editProfile__theme-photoWrapper>div{
        left: 51%;
    }

}
/*Navigation*/

.active__navigation__user__profile{
    width: 100% !important;
}


.active__navigation__user__profile ul{
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-right: 2%;
}
.active__navigation__user__profile li{
    font-size: 1.5rem;
    border-radius: 20px;
    padding: 8px 16px;
    margin: 0 !important;
    color: gray;
}

.active__user__profile{
    background-color: #ffc300;
    border-radius: 20px;
    padding: 10px 25px;
    color: white;
    margin: 0 !important;
    font-weight: bold;
}
/*User Profile*/
.profile__link__fake{
    display: none !important;
}
.user__profile img{
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    margin: 3% 0 3% 4%;
}

.user__profile__basic{
    padding: 2% 0 0 8%;
}

.profile__website{
    font-size: 1.4rem;
    text-align: center;
}

.profile__website li{
    padding-bottom: 1%;
}

.profile__website li:hover{
    text-decoration: underline;
}

.user__profile__basic h1{
    font-size: 3.2rem;
}
.user__profile__column3 h2{
    font-size: 2rem;
    padding-top: 3%;
}

.user__profile__column2 li{
    margin-right: 2%;
}

.user__profile__column3 p{
    white-space: pre-line;
    font-size: 1.4rem;
    padding: 2% 10%;
}
.padding-left-35{
    padding-left: 30%;
}

.opacity__profile{
    opacity: 0.6;
}

.user__profile__column5 li{
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;
    padding-bottom: 8%;
}

.user__profile__column5 ul{
    text-align: center;
    padding-left: 30%;
    list-style:square;
}
.user__profile__column7{
    margin-bottom: 10% !important;
}
.user__profile__column7 button{
    padding: 7px 30px;
    font-weight: 700;
    margin: 4% 38%;
}
.user__profile h2{
    text-align: center;
    font-size: 2rem;
    padding-top: 3%;
}

.back__text{
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    padding-top: 0;
    padding-left: 50%;
    color: #ff5733;
    font-size: 2.8rem;
    opacity: 0.5;
    font-weight: 600;
}
.text__back__box{
    position: relative;
}
.user__profile__column6 ul{
    padding-left: 20%;
}
.user__profile__column6 li{
    font-size: 1.8rem;
    padding-bottom: 8%;
}

.vertical__line{
    border-left: 4px solid #ff5733;
    opacity: 0.7;
    height: 6.5rem;
    margin-left: 8%;
}
.career__box h3{
    font-size: 2rem;
    padding-left: 1%;
    padding-top: 1%;
    color: rgb(74, 73, 73);
}

.career__box p{
    font-size: 1.4rem;
    padding: 2% 0 0 5%;
}

.career__box small{
    opacity: 0.6;
    font-size: 1.2rem;
}
.career__box__all{
    padding: 4% 0;
}

.link__icon{
    padding-right: 5px;
}

.company__info__icon{
    font-size: 2rem;
    color: gray;
    padding-right: 2%;
}

.company__info{
    padding-top: 2%;
}

.company__info li{
    padding: 2% 0;
    text-align: center;
    font-size: 1.8rem;
}
/*SNS*/
/* YouTube */
.flowbtn8.fl_yu2{
    border:solid 1px #fc0d1c;
    color:#fc0d1c;
    font-size:24px;	
}
.flowbtn8.fl_yu2:hover{
    border:solid 1px #fc0d1c;
    background:#fc0d1c;
}
/*All*/
.profile__card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 10px;
    margin: 5% 10%;
}

.width-100{
    width: 100%;
}

.unwrite__text{
    font-size: 1.4rem !important;
    opacity: 0.6 !important;
    text-align: center !important;
}

.profile__no__post{
    text-align: center;
    margin-top: 5%;
    font-size: 1.3rem;
    opacity: 0.7;
}

/*Profile Theme*/
.userProfile{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.userProfile__theme{
    height: 20rem;
    background-repeat: no-repeat;
    background-size: cover;
    background: lightgray;
}

.photoWrapper{
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    transform: translate(2rem, 13rem);
    background: lightgray;
}

.photoWrapper>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
    cursor: pointer;
}

.infoWrapper{
    padding: 1rem 1rem 1rem 2rem;
}

.userProfile__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFC300;
    margin-bottom: 3.9rem;
}

.userProfile__actions>div{
    padding: .5rem 1.5rem;
    border: 1px solid orange;
    border-radius: 2rem;
}

.userProfile__actions>div:hover{
    opacity: 0.7;
    cursor: pointer;
}

.moreWrapper{
    margin-left: auto;
    padding: .7rem .8rem .3rem !important;
    display: flex;
    width: 27%;
    border: none !important;
}
.moreWrapper input {
    width: 100% !important;
    padding: 4px !important;
    border-right: none;
    border-radius: 5px;
}

.moreWrapper:hover{
    background-color: transparent !important;
    cursor:default !important;
}

.clipIcon{
    color: #FFC300;
}

.followingWrapper{
    color: white;
    background-color: orange;
}

.resumeIconWrapper{
    margin-left: 1rem;
}
.mailWrapper{
    padding: .5rem .8rem !important;
    margin-left: 1rem !important;
}

.followWrapper{
    margin-left: 1rem !important;
}

.infoWrapper>h2{
    font-size: 1.9rem !important;
    font-weight: 800;
}

.infoWrapper>span{
    font-size: 1.5rem;
    color: orange;
}

.infoWrapper>p{
    font-size: 1.5rem;
    margin: .8rem 0;
}

.bioInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.5rem;
    margin-left: -.4rem;
}

.bioInfo>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFC300;
    margin-right: 2rem;
}

.bioInfo>div .MuiSvgIcon-root{
    margin-right: .5rem;
    font-size: 2rem !important;
}

.countInfo{
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    align-items: center;
}

.countInfo span{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    font-weight: 650;
    margin-right: 1rem;
    border-bottom: 1px solid white;
}

.countInfo span>p{
    color: gray;
    font-weight: normal;
    margin-left: .5rem;
}

.countInfo span:hover{
    border-bottom: 1px solid var(--hover);
}

.followedInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 ;
    flex: 1 1;
}

.followedInfo .MuiAvatar-root{
    width: 4rem;
    height: 4rem;
    margin-right: .5rem;
}

.followedInfo>span{
    color: lightgray;
    font-size: 1.5rem;
}

.blued{
    color: #FFC300 !important;
}

.themeBottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: space-between;
}

.themeSpinner{
    padding:  0 ;
    font-size: 2rem;
    color: var(--main-color);
    margin-left: auto;
}

.icon__check{
    color: green;
}

.icon__envelope__btn{
    background-color: transparent !important;
    border:none !important;
    color: #FFC300;
}

.profile__resume{
    padding: 1rem  0 0 5%;
}
.profile__resume__sns{
    display: flex !important;
}
.profile__resume__sns li{
    padding-right: 2rem;
}

.profile__resume__sns ul{
    display: flex;
}

.profile__resume__sns i:hover{
    cursor: pointer;
}

/*Icon*/
/* ボタン全体 */
.flowbtn8{
    border-radius:50%;
    position:relative;
    display:inline-block;
    width:40px;
    height:40px;
    font-size:17px;
    text-decoration:none;
    transition:.5s;
}

.flowbtn8:hover{
    color:#fff!important;
}

.flowbtn8 i{
    position:absolute;
    top:48%;
    left:50%;
    transform:translate(-50%,-50%);
}
/* Twitter */
.flowbtn8.fl_tw2{
    border:solid 1px #55acee;
    color:#55acee;
    font-size: 24px;
}

.flowbtn8.fl_tw2:hover{
    border:solid 1px #55acee;
    background:#55acee;
}
/* Instagram */
.flowbtn8.insta_btn2 {
    border:solid 1px #c6529a;
    color:#c6529a;
    font-size:28px;
}

.flowbtn8.insta_btn2:hover{
    border:solid 1px #c6529a;
    background:#c6529a;
}
/* Facebook */
.flowbtn8.fl_fb2{
    border:solid 1px #3b5998;
    color:#3b5998;
    font-size: 24px;
}

.flowbtn8.fl_fb2:hover{
    border:solid 1px #3b5998;
    background:#3b5998;
}

/* LINE */
.flowbtn8.fl_li2{
    border:solid 1px #00c300;
    color:#00c300;
    font-size:28px;
}

.flowbtn8.fl_li2:hover{
    border:solid 1px #00c300;
    background:#00c300;
}

/*Website*/

.profile__resume__website ul{
    display: flex;
}

.profile__resume__website li{
    padding-right: 4%;
    font-size: 15px;
}

.hover__underline:hover{
    text-decoration: underline;
}

/*Career*/
.profile__resume__career{
    margin-left: 5%;
    margin-right: 15%;
}

.profile__resume__career__details{
    display: flex;
    margin-top: 3%;

}

.profile__resume__career__details h2{
    padding-left: 2rem;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}


/*Skills*/

.profile__resume__skills{
    display: flex;
}

.profile__resume__skills h2{
    font-size:2.2rem;
}
.profile__resume__skills h3{
    padding-top: 1rem;
    font-size: 18px;
    padding-left: 20%;
}

.profile__resume__skills__right{
    padding-left: 44%;

}

.profile__resume__quali{
    display: flex;
}

.profile__resume__quali p{
    font-size: 17px;
    color: gray;
}

.profile__resume__quali h4{
    font-size: 24px;
    padding-left: 15%;
    padding-bottom: 1.4rem;
}

@media (max-width: 500px){
    .active__navigation__user__profile li{
        font-size: 1rem;
    }
    .profile__basic__info{
        display: block !important;
        text-align: center;
    }
    .user__profile img{
        margin: 0;
    }
    .user__profile__basic{
        padding: 0;
    }
    .countInfo{
        padding-left: 24%;
    }
    .profile__actionBtn{
        margin-left: 26% !important;
    }
    .company__info__icon{
        font-size: 1.6rem;
    }
    .company__info li{
        font-size: 1.4rem;
    }
    .user__profile__column7 button{
        font-size: 1.2rem;
        padding:7px 10px;
    }
    .user__profile__column7{
        margin-bottom: 18% !important;
    }

    .user__profile__column6 li{
        font-size: 1.5rem;
    }
    .user__profile__column6 ul{
        padding-left: 14% !important;
    }

    .user__profile{
        margin-bottom: 10%;
    }
}
.error-container {
  text-align: center;
  font-size: 106px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 70px 15px;
}
.error-container > span {
  display: inline-block;
  position: relative;
}
.error-container > span.four {
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background:
    linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
    linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
    linear-gradient(to right, #d89ca4, #e27b7e);
}
.error-container > span.four:before,
.error-container > span.four:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
}
.error-container > span.four:before {
  width: 43px;
  height: 156px;
  left: 60px;
  bottom: -43px;
  background:
    linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
    linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
    linear-gradient(to top, #99749D, #B895AB, #CC9AA6, #D7969E, #E0787F);
}
.error-container > span.four:after {
  width: 137px;
  height: 43px;
  transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(to right, #99749D, #B895AB, #CC9AA6, #D7969E, #E0787F);
}

.error-container > span.zero {
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%,  transparent 51%, transparent 100%),
    linear-gradient(to top right, #99749D, #99749D, #B895AB, #CC9AA6, #D7969E, #ED8687, #ED8687);
  overflow: hidden;
  -webkit-animation: bgshadow 5s infinite;
          animation: bgshadow 5s infinite;
}
.error-container > span.zero:before {
  content: '';
  display: block;
  position: absolute;
  transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background:
    linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
    linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
}
.error-container > span.zero:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 43px;
  bottom: 43px;
  background: #FDFAF5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
    
@-webkit-keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
}
    
@keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
}

/* demo stuff */

.not__foundPage h1 {
  text-align: center;
  margin: 30px 15px;
  color: gray;
}
.zoom-area { 
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.link-container {
  text-align: center;
}
.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #de7e85;
    padding: 10px 15px;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}

.link-container button{
  border: none;
  font-weight: bold;
}

.not__foundPage__logo{
  width: 220px;
  margin-left: 20%;
}

.not__foundPage{
  padding: 5% 0 0 30%
}

/*Non User*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif; width: 100% !important;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
       font-size:80px;
       }
       
       .link_404{      
  color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
    .contant_box_404{ margin-top:-50px;}
/*Privacy Modal*/
.privacy__modal__buttons{
    padding: 6% 20% 5% 20%;
}

.privacy__modal__buttons button{
    width: 100%;
    padding: 8px 20px;
    margin-top: 6%;
    border: none;
    background-color: transparent;
    font-weight: 600;
}

.privacy__modal__buttons p{
    font-size: 1.3rem;
}

.privacy__modal__buttons button:hover{
    background-color: #f5f5f5;
    border-radius: 6px;
}

/*About Us*/
.about-us{
    background-color: #f7f7f7;
    width: 100%;
    height: 120vh;
}
.about__us__img img{
    width: 60%;
    height: 60%;
}

.about__us__meg{
    float: right;
    margin-right: 5rem; 
    z-index: 100;
}

.about__us__meg h2{
    font-size: 1.8rem;
}

.about__us__meg p{
    font-size: 1.3rem;
    margin: 1rem 0 0 1rem
}

/*Help Center*/
.help__center{
    margin: 5rem 0 0 4rem;
}

.help__center-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 10px 20px;
    color: black;
}
.help__center-card button:hover{
    opacity: 0.7;
}
.help__center__content-btn{
    margin: 4% auto;
    display: flex;
    justify-content: space-evenly;
}

.help__center-card p{
    text-align: center;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.help__center-icon{
    font-size: 3rem;
}

.help__center__content{
    margin: 0 20%;
}

.contact-content-input{
    width: 100%;

}
.contact-content-input-input{
    width: 100%;
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.contact-content-input button{
    padding: 10px 20px;
    font-weight: bold;
    color: white;
    background-color: lightsalmon;
    border: none;
    border-radius: 5px;
    margin: 1rem auto;
    width: 45%;
    display: block;
}
.help__center-or{
    position: relative;
    padding: 0 65px;
    text-align: center;
    margin-top: 4rem;
  }
  
.help__center-or:before {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: #000;
}
  
.help__center-or span {
    position: relative;
    padding: 0 1em;
    background: #fff;
  }

.help__center h3{
    text-align: center;
    font-size: 1.5rem;
}


/*How to use*/

.how__to__use{
    width: 100%;
}
.how__to__use__content h3{
    font-size: 1.5rem;
}

.details-btn{
    font-size: 1.6rem;
    font-weight: bold;
    padding: 4px 20px;
    display: block;
    margin: 0 auto 2rem auto;
    background-color: transparent;
    border: none;
    opacity: 0.7;
}

.how__to__use-1-flex{
    display: flex;
    justify-content: space-evenly;
    margin: 4rem 0;
}

.how__to__use-2{
    background-color: #eee;
}

.how__to__use-3{
    margin-top: 6rem;
}

.how__to__use__content-box-tab button{
    border: 1px solid lightgray;
    padding: 1.5rem;
    border-radius: 50%;
    margin: 1rem;
    background-color: transparent;
}

.how__to__use__content-box-tab button:hover{
    opacity: 0.7;
    background-color: #eee;
}

.tabs-icon{
    font-size: 1.6rem;
}

.first__message{
    margin: 1rem 10%;
    font-size: 1.5rem;
}

.first__message h2{
    position: relative;
    margin: 30px;
    text-align: center;
}

.first__message h2::before, .first__message h2::after{
    position: absolute;
    z-index: 0;
    bottom: -10px;
    display: block;
    content: '';
    border: 1em solid #d90606;
}

.first__message h2::before{
    left: -30px;
    border-left-width: 15px;
    border-left-color: transparent;
}

.first__message h2::after{
    right: -30px;
    border-right-width: 15px;
    border-right-color: transparent;
}

.first__message h2 span{
    position: relative;
    z-index: 1;
    display: block;
    padding: 1rem 2rem;
    color: #fff;
    background: #fa4141;
}

.first__message h2 span::before, .first__message h2 span::after{
    position: absolute;
    bottom: -10px;
    display: block;
    width: 10px;
    height: 10px;
    content: '';
    border-style: solid;
    border-color: #b70505 transparent transparent transparent;
}

.first__message h2 span::before{
    left: 0;
    border-width: 10px 0 0 10px;
}

.first__message h2 span::after{
    right: 0;
    border-width: 10px 10px 0 0;
}

.first__message-content{
    margin: 2rem;
}

.first__message-content h3{
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-decoration: underline;
}

.first__message-content button{
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
}


@media (max-width: 480px){
    .help__center{
        margin: 2rem 0;
    }
    .help__center-card p{
        font-size: 1rem;
    }

    .help__center-icon{
        font-size: 2rem;
    }

    .help__center-card{
        padding: 5px;
    }
}


/*Sign up*/
.auth__form-flex{
    display: flex;
}

.auth_form{
    width: 100%;
}

.auth__form-content{
    margin: 15% 0 0 5%;
}

.auth__form-content h2{
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.auth__form-content-input input{
    width: 100%;
    margin-bottom: 2rem;
    padding: 10px 10px 10px 15px;
    font-size: 1.5rem;
    border-radius: 20px;
    border: 0.5px solid lightgray;
    background:whitesmoke;
    color: gray;
}

.auth__form-content-signup input{
    width: 80%;
}
.auth__form-content-margin{
    margin-top: 3rem;
}
.auth__form-signup-img{
    width: 40%;
}

.auth__form-content-logo img{
    height: 2.8rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: 3rem;
}
.auth__form-content-btn{
    text-align: center;
}
.auth__form-content-signup-btn{
    text-align: center;
    margin-top: 3rem;
}

.auth__form-content-btn button {
    background-color: #ffc300;
    color: white;
    font-weight: bold;
    border:none;
    padding: 10px 20px;
}
.auth__form-border{
    border-bottom: 2px solid gray;
    margin: 0 30%;
}
.auth__form-info{
    margin-top: 4rem;
    font-size: 1.4rem;
}

.auth__form-info span{
    font-weight: bold;
    opacity: 0.8;
}

.auth__form-change1{
    margin-bottom: 2rem;
}
.auth__form-change2{
    margin-top: 2rem;
} 
.auth__form-info-flex{
    display: flex;
}
.auth__form-info-center p{
    text-align: center !important;
}
.auth__form-signup-content{
    margin: 4% 2rem;
    text-align: center;
    width: 50%;
}

.auth__form-signup-content-logo img{
    width: 20rem;
    margin-bottom: 2rem;
}

.auth__form-use{
    display: flex;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-left: 10%;
}

.auth__form-info p{
    color: gray;
    text-align: center;
}
.auth__form-use input{
    margin-top: 2.5px;
}

.auth__form-content-signup-center{
    margin-left: 30%;
    margin-top: 2rem;
}

/*Logout*/
.sign__out{
    width: 100%;
    margin: 10% 0 0 0;
}

.sign__out button{
    font-size: 1.8rem;
    padding: 8px 20px;

}

.sign__out h2{
    font-size: 2rem;
    text-align: center;
}

.error{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    color: red;
    border: 0.2rem solid red;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;  
}

/*Media Query*/
@media (min-width: 601px) and (max-width: 991px){
    .auth__form-flex img{
        width:70%;
        height: 100%;
    }
    .auth__form-content{
        margin: 5% 0 0 -13rem;
    }
    .auth__form-signup-content{
        margin: 5% 0 1rem 0;
    }
    .auth__form-signup-img{
        width: 100%;
        margin-right: -8rem;
    }
    .auth__form-content-signup-center{
        margin-left: 4rem;
    }
    .auth__form-signup-content-logo{
        width: 2.5rem;
    }
}
@media (max-width: 480px){
    .auth__form-content{
        margin: 0;
    }
    .auth__form-signup-content{
        margin: 7rem 0 0 1rem !important;
        width: 90%;
    }
    .auth__form-content-signup-center{
        margin-left: 10%;
    }
    .auth__form-flex img{
        display: none;
    }
    .auth__form-content{
        margin: 30% 0 3rem 1rem;
        width: 90%;
    }
    .auth__form-border{
        margin: 0 28% 0 32%;
    }
    .auth__form-content-input{
        margin: 2rem 2rem 0 2rem;
    }
    .auth__form-info{
        margin-top: 8rem;
    }
    .auth__form-flex{
        display: block;
    }
    .auth__form-info{
        font-size: 1.4rem !important;
    }
    .auth__form-use input{
        margin-top: 3px;
        margin-right:3px;
        height: 10px;
        width: 10px;
    }
}



@media (max-width: 600px){
    .auth__form-flex img{
        display: none;
    }
    .auth__form-info{
        margin-bottom: 18%;
    }
    .auth__form-content-signup{
        width: 100%;
    }
}
.delete__account-modal{
    margin: 4% 3%;
}

.delete__account-modal button{
    background-color: red;
    color: white;
    padding: 8px 20px;
    font-weight: bold;
    margin: 4% 0 0 43%;
}

.delete__account-modal ul{
    font-size: 1.4rem;
    text-align: center;
    margin-top: 15%;
}

.delete__account-modal li{
    margin-bottom: 3%;
}

.delete__account-input{
    margin-top: 15%;
}
.forget__password{
    background-color: #f5f5f5;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
	display: flex;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	align-items: center;
	justify-content: center;
}

.forget__password form{
    padding-top: 10px;
	font-size: 14px;
	margin-top: 30px;
}

.card-body{
    background-color: white;
    border: 1px solid lightgray;
    padding: 10%;
}

.card-title{ 
    font-weight:300;
    font-size: 1.7rem;
    text-align: center;
    margin-bottom: 2rem;
}

.btn{
	font-size: 14px;
    display: block;
    margin: 20px auto 0 auto;
    padding: 8px 18px;
    background-color: #ff5733	;
    border: none;
    color: white;

}

.card-text p{
    font-size: 1rem !important;
}

.forget__password span{
	font-size:14px;
}

.form-group input{
    padding: 8px 10px;
    display: block;
    margin: 20px auto 0 auto;
    width: 90%;

}

.form-group input:focus{
    outline: none;
}

.card-body p{
    font-size: 1.5rem;
    padding-top: 4%;
}

.logo__form__page{
    display: block;
    margin: 0 auto 1rem auto;
    width: 35%;
}


input[type=number] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

.width-50rem{
    width: 50rem;
}

.resend__code{
    border-top: 1px solid gray;
    margin: 0 auto 1rem auto;
    width: 50%;
    margin-top: 2rem;
}
.resend__code button{
    padding: 3px 13px;
    background-color: transparent;
    display: block;
    margin: 20px auto 0 auto;
}

@media screen and (max-width: 500px){
    .form-group label{
        font-size: 1.2rem;
    }
    .logo__success__logo{
        padding-left: 10%;
    }
}
.mobile-flex{
  display: flex;
}
.search__box{
  width: 100%;
}

.search__box-content{
  border-radius: 20px;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 2rem;
  position: relative;
}

.search__box-content select{
  width: 11rem;
  padding: 1rem;
  border: none;
  border-right: 1px solid lightgray;
  color: gray;
  outline: none;
}

.search__box-content option{
  color: black;
}

.search__box-content option:disabled{
  color: lightgray;
}
.search__box-content2 input{
  width: 22rem;
  padding: 1rem;
  margin-left: 4px;
  border: none;
  outline: none;
}

.search__box-content-btn button{
  background-color: lightsalmon;
  border-radius: 30px;
  padding: 10px 20px;
  color: white;
  border: none;
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

.search__box-content-user input{
  padding: 1rem;
  margin-left: 4px;
  width: 80%;
  outline: none;
  border: none;
}

.search__box-content-user button{
  background-color: lightsalmon;
  border-radius: 30px;
  padding: 10px 20px;
  color: white;
  border: none;
}

.search__box-content-data{
  padding: 1rem;
  margin-left: 4px;
  border: none;
  border-right: 1px solid lightgray;
  color: gray;
}

.search__box-content2-event{
  width: 22rem;
}

/*Word Search*/
.words__search-flex{
  display: flex;
}

.words__search-flex button{
  padding: 3px 10px;
  background-color: transparent;
  margin: 1rem;
  border: none;
  color: black;
}

.words__search-flex button:hover{
  text-decoration: underline;
}

.words__search-content{
  position: relative;
  margin: 5em 0;
  padding: 0.5em 1em;
  border: solid 3px #eee;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.words__search-content .words__search-content-title{
  position: absolute;
  display: inline-block;
  top: -13px;
  left: 10px;
  padding: 0 9px;
  line-height: 1;
  font-size: 19px;
  background: #FFF;
  color: #ffc300;
  font-weight: bold;
}

@media (max-width: 480px){
  .mobile-flex{
    display: block;
  }
  .search__box-content select{
    border: none;
  }
  .search__box-content1{
    border-bottom: 2px dotted gray;
    padding-bottom: 4px;
  }
  .search__box-content2{
    padding-top: 4px;
  }
  .search__box-content-btn button{
    border-radius: 30px;
    padding: 8px 15px;
    color: white;
    border: none;
    position: absolute;
    right: 0;
    margin-right: 2rem;
    font-size: 1.2rem;
  }
  .search__box-content select{
    width: 9.2rem;
    font-size: 1.2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    background-color: transparent;
  }
  .search__box-content-data{
    border: none;
    width: 10rem;
    margin-top: -10px;
    background-color: transparent;
    color: gray;
    border-radius: 0;
    margin-left: 0;
    padding: 0;
  }

  .words__search-flex button{
    padding: 0;
    margin: 6px;
    font-size: 1.2rem;
  }
  .words__search{
    margin: 0 1rem;
  }
  .mobile-btn-margin button{
    margin-top: -2rem;
  }
  .search__box-content{
    margin: 1rem;
  }

  .search__box-content input[type='date']::before{
    content: attr(placeholder);
    font-size: 1.2rem;
    width: 100%;
    color: gray;
  }

  .search__box-content input[type='date'].filled::before{
    content: '' !important;
    color: white;
  }
  .search__box-content input[type='date']:not(.filled)::before{
    color: gray;
    content: attr(placeholder);
  }
}


.notification__content strong{
    padding-right: 1rem;
}

.date__notification{
    position: absolute;
    right: 0;
    margin-right: 32%;
}

.notification__bar{
    width: 100%;
    padding: 1% 0 1% 6px;
    margin-bottom: 2rem;
    border-bottom: 1px solid lightgray;
}

.notification__bar:hover{
    background-color: whitesmoke;
    border-radius: 15px;
}

.notification__bar a{
    display: flex;
}

.notification__bar p{
    padding-left: 1rem;
    padding-top: 1.2%;
    font-size: 1.4rem;
}
.notification__content{
    width: 62%;
    margin-top: 2rem;
}

.unread__notification{
    background-color: #eee;
    border-radius: 15px;
}

.unread__notification:hover{
    background-color: lightgray;
    opacity: 0.7;
}

.noNotification__content{
    margin-top: 5rem;
}
.noNotification__content img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}

.noNotification__content p{
    font-size: 2rem;
    text-align: center;
    margin-top: 3rem;
}


@media screen and (max-width: 768px){
    .noNotification__content img{
        width: 60%;
    }
    .noNotification__content p{
        font-size: 1.4rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px){
    .sidebarChat__noExist img{
        width: 50%;
    }
    .how__toStart p{
        font-size: 2rem;
    }
}


.timeLine__home{
    display: flex;
}

.company__search{
    margin-left: 5%;
    border-left: 1px solid lightgray;
}

.timeLine__load__btn{
    background-color: transparent;
    border: none;
    color: black;
}

.refresh__btn{
    font-size: 2rem;
}

.jobs_timeLine-btn{
    display: flex;
    margin: 5px 0 0 5px;
}

.jobs_timeLine-btn button{
    padding: 7px 12px;
    margin-right: 1rem;
    border: none;
    background-color: #eee;
    border-radius:5px;
    font-weight: 700;
    opacity: 0.8;
}

.jobs_timeLine-btn button:hover{
    -webkit-text-decoration: underline blue;
            text-decoration: underline blue;
    color: blue;
}

/*Home Back*/
.home__back__image{
    width: 100%;
    background-size: cover;
    height: 30rem;
    background: 
    linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(/static/media/homeBack.70bc4f89.png);
    background-position-x: center;
    background-position-y:center;
}


.home__back__image h2{
    color: white;
    text-align: center;
    font-size: 3.5rem;
    padding-top: 14%;
}
.home__back__btn{
    margin-top: 2rem;
}

.home__back__image span{
    border-radius: 20px;
    text-align: center;
}

.home__back__btn button{
    margin: 0 auto;
    display: block;
    padding: 9px 22px;
    font-size: 1.6rem;
    font-weight: bold;
    color: white;
    background-color: #ff5349;
    border: #ffc300;
    box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

.home__back__btn button:hover{
    opacity: 0.7;
}

.home__back-mobile{
    display: none;
}

/*Kind of Job*/
.timeline__content__box{
    padding: 3rem 5%;
    border-bottom:  1px solid lightgray;
    margin-top: 5%;
}

.kindof__job__timeline ul{
    font-size: 1.5rem;
    margin-left: 5rem;
}

.kindof__job__timeline li{
    margin: 0 0 1.5rem 6rem;
}

.kindof__job__timeline-icon{
    padding-right: 1rem;
}


.feed {
    flex: 1 1;
    border-right: 1px solid #e6ecf0;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

.feed__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--copipel-background);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1px;
}

.feed__header-ava{
    margin-right: 2rem;
}

.feed__header > h2 {
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
}

.feed__loader{
    width: 100%;
    padding-top: 2rem;
    display: flex;
    justify-content: center !important;
}

.feed::-webkit-scrollbar{
    display: none;
}

.profile__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid #e6ecf0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .7rem 1rem;
}

.forFollow{ 
    border-bottom: 1px solid white;
}

.profile__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.profile__backArrow:hover{
    background: whitesmoke;
}

.profile__header .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: #ffc300;
}

.profile__title{
    display: flex;
    flex-direction: column;
}

.profile__title_title{
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.profile__header h2{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}

.profile__header span{
    font-size: 1.3rem;
    color: var(--twittie-light);
}


.status__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid #e6ecf0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .7rem 1rem;
}


.status__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.status__backArrow:hover{
    background: whitesmoke;
}

.status__header .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: #ffc300;
}

.status__title{
    display: flex;
    flex-direction: column;
}

.status__title_title{
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.status__header h2{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}

.status__header span{
    font-size: 1.3rem;
    color: var(--twittie-light);
}

.non__posts{
    font-size: 2rem;
    padding: 5% 0 0 34%;
    opacity: 0.4;
    font-weight: 700;
}

.header-text{
    padding: 1rem 2rem;
    border-left: 4px solid #ffc300;
}

/*Info*/
.timeline__info-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 12px 24px;
}

.timeline__info-card-margin{
    margin: 2% 5%;
}

.timeline__info-card h3{
    text-align: center;
}
.info-card{
    width: 16rem;
}

@media (max-width: 1282px){
    .feed {
        flex: 1 1 !important;
    }
}

@media (min-width: 500px){
    .feed__header-ava{
        display: none;
    }
    
}

@media (max-width: 480px) {
    .feed{
        margin-bottom: 12%;
    }
    .timeline__info-card{
        padding: 10px 0;
    }
    .home__back-mobile{
        display: block;
    }
}

@media (max-width:320px){
    .home__back__btn{
        margin: 6% 0 5% 22%;
    }
}

.chat{
    flex: 3 1;
    display: flex;
    flex-direction: column;
    border-right:1px solid #e6ecf0;
}

.chat__header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid #e6ecf0;
    padding: 1rem 2rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3.8rem;
}

.chat__header>h2{
    font-size: 2rem;
    flex: 1 1;
    font-weight: 800;
    margin-right:2rem
}

.chat__header-ava{
    margin-right: 2rem;
}

.chat__header-ava .MuiAvatar-root{
    margin: -2rem 0rem -2rem 0rem;
}

.chat .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: #ffc300;
}

.chat__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.chat__backArrow:hover{
    background: #f5f8fa;
}

.chat__message {
    position: relative;
    font-size: 1.6rem;
    padding: 0.5rem 2rem;
    background-color: #f5f8fa ;
    border-radius: 5px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: .3rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.chat__message img{
    border-radius: 0 2rem 2rem 0;
    object-fit: cover;
    max-width: 42.5rem;
    width: 100%;
    cursor: pointer;
}

.chat__message>span{
    padding: 0.5rem 0;
    min-width: 2.3rem;
}

.chat__item{
    margin-bottom: 1rem;
    word-break: break-all
}

.chat__receiver{
    margin-left: auto;
    background-color: #eee;
    color: black;
    border-radius: 2rem .2rem .2rem 2rem;
    white-space: pre-line;
}

.chat__receiver>img{
    border-radius: 2rem .2rem .2rem 2rem !important;
}

.chat__receiver span{
    text-align: end;
}

.chat__timestamp{
    font-size: xx-small;
    text-align: end;
}

.lefted{
    text-align: start !important ;
}

.chat__name {
    position: absolute;
    top: -1.5rem;
    font-weight: 800;
    font-size: xx-small;
}

.chat__headerRight{
    display: flex;
    justify-content: space-between;
    min-width: 10rem;
}

.chat__body{
    flex: 1 1;
    background-repeat: repeat;
    background-position: center;
    padding: 1rem;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.chat__body::-webkit-scrollbar{
    display: none;
}

.chat__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9rem;
    border-top: 1px solid lightgray;
    padding: 0 1rem 0 .6rem;
}

.chat__footer-ImageBox{
    position: absolute;
    bottom: 7rem;
    border-radius: 2rem;
    width: 19rem;
    height: 23rem;
    background: #ffc300;
}

.chat__footer-ImageBox>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
}

.chat__footer > form {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.chat__footer>form>textarea{
    flex: 1 1;
    padding: 0.5rem 1rem 0 2rem;
    border-bottom: .2rem solid #ffc300;
    border-top: none;
    border-left: none;
    line-height: 2rem;
    font-size: 1.8rem !important;
    border-right: none;
    outline: none;
    resize: none;
}

.chat__footer>form>button{
    display: none;
}

.chat__footer .MuiSvgIcon-root{
    font-size: 3rem !important;
    cursor: pointer;
}

.chat__footer-emowrapper {
    padding: .4rem 1rem .3rem 0rem;
    margin-left: -.1rem;
    margin-right: 1rem;
}

.readyBtn{
    color: orange !important;
}

.spinnerSpan{
    font-size: 2rem;
    color: #ffc300;
}


@media screen and (max-width: 500px){
    .chat{
        flex: 1 1;
        height: calc(100vh - 4.5rem);
    }
    .chat__footer>form>input{
        width: calc(100% - 1rem );
    }

    .chat__footer-ImageBox {
        bottom: 11rem;
    }
    .chat__footer>form>textarea{

        line-height: 1.5rem;
        font-size: 1.4rem !important;
    }
    .chat__footer{
        height: 7rem;
    }
}

@media screen and (min-width: 914px){
    .chat__backArrow{
        display: none;
    }
}


.profile__top__link input{
    width: 100%;
}

.profile__top__link span{
    display: block;
    overflow: hidden;
    padding-right:10px;
}
.profile__top__link button{
    width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.2rem;
}

.check-icon{
    padding-left: 10px;
    font-size: 1.4rem;
    color: gray;
}

.check-icon-success{
    color: green;
}

.profile__top__link-mobile{
    display: none !important;
}

.feed h4{
    text-align: center;
    margin-top: 2rem;
}

@media screen and (max-width: 500px){
    .profile__top__link-pc{
        display: none;
    }
    .profile__top__link-mobile{
        display: block !important;
        
    }
    
}

@media screen and (min-width: 500px){

}


.company__dashboard__mini i{
    font-size: 2rem;
    padding: 1.5rem;
    background-color: #f4f7fe;
    border-radius: 50%;
    color: #432bfb;
}

.company__dashboard__mini{
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 20px;
    margin: 1rem;
}

.company__dashboard__mini-content{
    margin-left: 2rem;
    margin-top: 1rem;
}

.company__dashboard__mini-content h3{
    font-size: 1.3rem;
    color: gray;
}

.company__dashboard__mini-content h5{
    font-size: 1.8rem;
    margin-top: 1rem;
}


/*nav bar*/
.company__dashboard-icon{
    font-size: 2rem;
    padding-right: 1rem;
}


.company__dashboard-post-tabs{
    width: 80%;
}

.d-block{
    display: block;
}


/*Manage*/
.company__dashboard_postmanage-list{
    margin: 2rem 10% 1rem 10%;
}

/*Search*/

.company__dashboard-search{
    margin: 1rem 10%;
}

.company__dashboard-search input{
    width: 70%;
    height: 4rem;
    margin-top: 2.6rem;
    margin-right: 1rem;
}

/*Contact*/
.company__dashboard-contact{
    margin: 2rem 25%;
}

.company__dashboard-contact-content{
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
}

.company__dashboard-contact-content h2{
    font-size: 1.8rem;
    margin-top: 1rem;
}

.company__dashboard-3{
    margin: 2rem;
}

@media (max-width: 480px) {
    .sidenavigation{
        display: none;
    }

    .company__dashboard-post-tabs{
        width: 100%;
    }
}
.company__post-list-content{    
    border: 1px solid gray;
    border-radius: 10px;
    margin: 1rem 0;
    padding: 8px 2rem;
    background-color: white;
}

.company__post-list-content h2{
    font-size: 1.6rem;
    margin-bottom: 1rem;
}

.company__post-list-content p{
    font-size: 1.5rem;
    margin-top: 0.5rem;
}

.company__post-list-content-under{
    border-top: 1px solid lightgray;
    margin: 1rem 2rem;
}

.company__post-list-content-under button{
    margin: 1rem 2rem;
    padding: 5px 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    width: 12rem;
}

.company__post-list-flex{
    display: flex;
}

.company__post-list-edit-input select{
    padding: 4px 8px;
    width: 50%;
    margin: 0 1rem;
    border-radius: 5px;
    font-size: 1.5rem;
}

.company__post-list-edit-input input{
    padding: 4px 8px;
    width: 47%;
    margin: 0 1rem;
    border-radius: 5px;
    font-size: 1.5rem;
}
.company__post-list-edit{
    font-size: 1.8rem;
}

.company__post-list-edit-back{
    background-color: white;
    padding: 2rem;
}

.company__post-list-edit textarea{
    overflow-y: scroll !important;
    resize: none !important;
    padding: 4px 8px;
    width: 95%;
    border-bottom: 2px solid gray;
    margin: 2rem 1rem 0 1rem;
    font-size: 1.5rem;
}

.company__post-list-edit-input{
    margin-top: 2rem;
}

.company__post-list-submit{
    padding: 3px 15px;
    margin: 2.5rem 0 1rem 82%;
    background-color: #ffc300;
    color: white;
    border: 0.3px solid black;
    font-weight: bold;
    border-radius: 5px;
    font-size: 1.4rem;
}

@media (max-width: 480px) {
    .company__post-list-submit{
        margin: 2.5rem 0 1rem 55%;
    }
}
.admin__dashboard__mini i{
    font-size: 2rem;
    padding: 1.5rem;
    background-color: #f4f7fe;
    border-radius: 50%;
    color: #432bfb;
}

.admin__dashboard__mini{
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 20px;
}

.admin__dashboard__mini-content{
    margin-left: 2rem;
    margin-top: 1rem;
}

.admin__dashboard__mini-content h3{
    font-size: 1.3rem;
    color: gray;
}

.admin__dashboard__mini-content h5{
    font-size: 1.8rem;
    margin-top: 1rem;
}
.chart__box{
    background-color: white;
    padding: 2rem;
    margin: 0.5rem;
    border-radius: 20px;
}
/*Get User*/
#userTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
  }
  
#userTable td, #userTable th {
  padding: 1.5rem 3rem;
}

#userTable td{
  font-size: 1.5rem;
}

#userTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: gray;
    font-size: 1.2rem !important;
    border-bottom: 1px solid lightgray;
}

.userAdmin__popOver{
  z-index: 100;
  background-color: red;
  padding: 10%;
  width: 100%;
  border-radius: 10px;
}

.mailTo__link:hover{
  text-decoration: underline !important;
}

.mailTo__link{
  font-size: 1.4rem !important;
}

.get__user button{
  border: none;
  border-left: 1px solid #eee;
  background-color: transparent;
}
.admin__dashboard-1-content{
    justify-content: space-evenly;
    margin-top: 2rem;
}

.admin__dashboard-2-chart{
    width: 55%;
    margin: 4rem 0 2rem 0;
}

.admin__dashboard-2-flex{
    display: flex;
    justify-content: space-evenly;
    margin: 0 3rem;
}

.admin__dashboard-3-content{
    justify-content: space-evenly;
}
.admin__dashboard-3-card{
    background-color: white;
    padding: 1.5rem;
    border-radius: 20px;
    margin: 2rem 8rem 0 8rem;
}


* {
    margin: 0;
}


html{
    font-size: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

:root{
    --copipel-background: #e6ecf0;
    --copipel-background-2: #f5f8fa;
    --main-color: #ffc300;
    --hover: white;
    --hover-2: white;
    --red: rgb(224, 36, 94); 
}

.app{
    display: flex;
    height: auto;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;

    flex-direction: column;
}

.app__mainContent{
    display: flex;
    flex: 1 1;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.react-responsive-modal-modal{
    top: 4.5vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 85vh;
    border-radius: 2rem;
    padding: 0;
    width: 85vh;
}

.react-responsive-modal-closeButton{
    display: none;
}

.MuiCircularProgress-colorPrimary{
    color: #ffc300 !important;
}

.app__main{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.app__main__cv{
    display: flex;
}

.app__main__cv__left{
    position: absolute;
    left: 0;
}

.app__main__cv__right{
    padding-left:10%;
}

.MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.emoji-picker-react input.emoji-search{
    border: 2px solid #ffc300 !important;
    border-radius: 3rem;
}

ul{
    list-style: none;
    padding: 0;
}

.tabbarMenu .react-swipeable-view-container .MuiBox-root-6 {
    padding: 0;
}

.addMoreLeftPadd{
    padding-left: 2rem;
}
button:hover{
    cursor: pointer;
}

.app__main__search{
    position: absolute;
    right: 0;
    margin-right: 10%;
    margin-top: 4%;

}

.app__admin{
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    background-color: #f4f7fe;
}

.app__admin__wrapper{
    overflow-y: scroll;
    width: 100%;
    padding: 1.6rem 0;
    padding-top: 0;
    -webkit-animation: fadeIn .5s ease;
            animation: fadeIn .5s ease;
    position: relative;
}

.d-block{
    display: block !important;
}

.d-flex{
    display: flex !important;
}
.widget__empty{
    width: 20% !important;
}

a{
    text-decoration: none;
    color: black;
}

.cursor:hover{
    cursor: pointer;
}

.underline:hover{
    border-bottom: 1px solid black;
}

.text-center{
    text-align: center;
}

.opacity:hover{
    opacity: 0.7;
}

@media screen and (max-width: 500px){
    .app__mainContent{
        height: calc(100vh - 4.5rem);        
    }
    .widget__empty{
        display: none;
    }
    .react-responsive-modal-modal{
        width: 32rem;
    }
}
.sidenavigation {
    width: 250px;
    height: 100%;
    background: black; 
    position: relative;
    padding-top: 1rem;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    background-color: white;
  }
.sidenavigation h3{
    font-size: 2.5rem;
    text-align: center;
    border-bottom: 2px solid lightgray;
    padding-bottom: 3rem;
    margin-top: 1rem;
}
.sidenavigation-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    font-weight: bolder;
}

.sidenavigation-menu {
    padding: 1.6rem 0 1.6rem 3.2rem;
    display: block;
    color: gray;
    font-weight: bolder;
    opacity: .8;
    font-size: 1.5rem !important;
}

.sidenavigation-menu:hover{
    background: white;
    text-decoration: none;
    opacity: 1;
}

.sidenavigation-menu-active {
    background: white;
    opacity: 1;
    border-right: 6px solid #432bfb;
    color: #432bfb ;
}

.admin__sidebar-icon{
    padding-right: 1rem
}

.color-black{
    color: black;
}

.color-white{
    color: white;
}
/*Margin*/
.margin-top-4rem{
    margin-top: 4rem;
}



/*Padding*/
.padding-right-3px{
    padding-right: 3px;
}


.width{
    width: 3rem;
}

.font-size2rem{
    font-size: 2rem;
}
