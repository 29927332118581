/*ADD ALT */
.alt__wrapper{
    display: flex;
    flex-flow: column;
    height: 100%
}

.alt__wrapper .alt__imageContainer{
    flex: 1 1 auto;
}

.alt__imageContainer img {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 27em;
}

.alt__addDescription {
    padding-top: 2rem;
}

.alt__addDescription h2{
    color: var(--main-color);
    margin-left: 1rem;
}

.alt__addDescription textarea{
    margin-left: 1rem;
    border: none;
    width: 100%;
    font-size: 1.4rem;
    outline: none;
    padding: 0;
}


/*CROP PHOTO*/
.cropImage__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cropImage__wrapper:first-child{
    flex: 1;
}

.cropImage__imageSelection{
    width: 100%;
    height: 100%;
}

.blued {
    color: var(--main-color);
}

.cropImage__wrapper .ReactCrop__crop-selection{
    border: 4px solid var(--main-color);
    border-radius: 2rem;
}

.cropImage__wrapper .ReactCrop__image{
    width: 100%;
    height: auto;
    /* height: 35rem; */
    object-fit: contain;
    margin: 0 auto !important;
}

.cropImage__changeAspectRatio-actions{
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0 0;
}

.cropImage__changeAspectRatio-actions>div{
    flex: 1;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton{
    display: flex;
    flex-direction: row;
}

.cropImage__changeAspectRatio-actions .cropImage__scaleButton>div{
    flex: 1;
    text-align: center;
}

.cropImage__slider{
    padding-left: 3em;
}

.cropImage__slider .MuiSlider-root {
    padding: unset!important;
    color: var(--main-color);
}

