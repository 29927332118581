.tabbarMenu .MuiTabs-root{
    background: white;
    text-transform: unset !important;
}

.tabbarMenu .MuiSvgIcon-root{
    font-size: 2.3rem;
}
.tabbarMenu .MuiTab-fullWidth:hover {
    background: #FFCC66;
    opacity: 0.3;
}

.tabbarMenu .MuiPaper-elevation4{
    box-shadow: none;
}

.tabbarMenu .MuiTab-textColorPrimary.Mui-selected{
    color: orange;
    font-weight: bold;
}

.tabbarMenu .PrivateTabIndicator-root{
    background-color: orange;
}

.tabbarMenu .MuiTabs-indicator {
    background-color: orange;
}

.tabbarMenu .react-swipeable-view-container .MuiBox-root-6{
    padding: 1rem;
}

.tabbarMenu .MuiBox-root {
    padding: 0rem;
    overflow: hidden;
}

.tabbarMenu .MuiTab-wrapper {
    font-size: 1.5rem;
    text-transform: none;
}

.btn__save{
    color: white !important;
    background-color: #FFC300 !important;
    margin: 2% !important;
    font-size: 1.4rem !important;
    padding: 5px 15px !important; 
    font-weight: bold !important;
}

@media screen and (max-width: 320px) {
    .tabbarMenu .MuiTab-wrapper{
        font-size: 1rem;
    }
    .tabbarMenu .react-swipeable-view-container .MuiBox-root-6{
        padding: 0.2rem;
    }
}