/*nav bar*/
.company__dashboard-icon{
    font-size: 2rem;
    padding-right: 1rem;
}


.company__dashboard-post-tabs{
    width: 80%;
}

.d-block{
    display: block;
}


/*Manage*/
.company__dashboard_postmanage-list{
    margin: 2rem 10% 1rem 10%;
}

/*Search*/

.company__dashboard-search{
    margin: 1rem 10%;
}

.company__dashboard-search input{
    width: 70%;
    height: 4rem;
    margin-top: 2.6rem;
    margin-right: 1rem;
}

/*Contact*/
.company__dashboard-contact{
    margin: 2rem 25%;
}

.company__dashboard-contact-content{
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
}

.company__dashboard-contact-content h2{
    font-size: 1.8rem;
    margin-top: 1rem;
}

.company__dashboard-3{
    margin: 2rem;
}

@media (max-width: 480px) {
    .sidenavigation{
        display: none;
    }

    .company__dashboard-post-tabs{
        width: 100%;
    }
}