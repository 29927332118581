.chat{
    flex: 3;
    display: flex;
    flex-direction: column;
    border-right:1px solid #e6ecf0;
}

.chat__header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid #e6ecf0;
    padding: 1rem 2rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3.8rem;
}

.chat__header>h2{
    font-size: 2rem;
    flex: 1;
    font-weight: 800;
    margin-right:2rem
}

.chat__header-ava{
    margin-right: 2rem;
}

.chat__header-ava .MuiAvatar-root{
    margin: -2rem 0rem -2rem 0rem;
}

.chat .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: #ffc300;
}

.chat__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.chat__backArrow:hover{
    background: #f5f8fa;
}

.chat__message {
    position: relative;
    font-size: 1.6rem;
    padding: 0.5rem 2rem;
    background-color: #f5f8fa ;
    border-radius: 5px 20px;
    width: fit-content;
    margin-bottom: .3rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.chat__message img{
    border-radius: 0 2rem 2rem 0;
    object-fit: cover;
    max-width: 42.5rem;
    width: 100%;
    cursor: pointer;
}

.chat__message>span{
    padding: 0.5rem 0;
    min-width: 2.3rem;
}

.chat__item{
    margin-bottom: 1rem;
    word-break: break-all
}

.chat__receiver{
    margin-left: auto;
    background-color: #eee;
    color: black;
    border-radius: 2rem .2rem .2rem 2rem;
    white-space: pre-line;
}

.chat__receiver>img{
    border-radius: 2rem .2rem .2rem 2rem !important;
}

.chat__receiver span{
    text-align: end;
}

.chat__timestamp{
    font-size: xx-small;
    text-align: end;
}

.lefted{
    text-align: start !important ;
}

.chat__name {
    position: absolute;
    top: -1.5rem;
    font-weight: 800;
    font-size: xx-small;
}

.chat__headerRight{
    display: flex;
    justify-content: space-between;
    min-width: 10rem;
}

.chat__body{
    flex: 1;
    background-repeat: repeat;
    background-position: center;
    padding: 1rem;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.chat__body::-webkit-scrollbar{
    display: none;
}

.chat__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9rem;
    border-top: 1px solid lightgray;
    padding: 0 1rem 0 .6rem;
}

.chat__footer-ImageBox{
    position: absolute;
    bottom: 7rem;
    border-radius: 2rem;
    width: 19rem;
    height: 23rem;
    background: #ffc300;
}

.chat__footer-ImageBox>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
}

.chat__footer > form {
    flex: 1;
    display: flex;
    align-items: center;
}

.chat__footer>form>textarea{
    flex: 1 1;
    padding: 0.5rem 1rem 0 2rem;
    border-bottom: .2rem solid #ffc300;
    border-top: none;
    border-left: none;
    line-height: 2rem;
    font-size: 1.8rem !important;
    border-right: none;
    outline: none;
    resize: none;
}

.chat__footer>form>button{
    display: none;
}

.chat__footer .MuiSvgIcon-root{
    font-size: 3rem !important;
    cursor: pointer;
}

.chat__footer-emowrapper {
    padding: .4rem 1rem .3rem 0rem;
    margin-left: -.1rem;
    margin-right: 1rem;
}

.readyBtn{
    color: orange !important;
}

.spinnerSpan{
    font-size: 2rem;
    color: #ffc300;
}


@media screen and (max-width: 500px){
    .chat{
        flex: 1;
        height: calc(100vh - 4.5rem);
    }
    .chat__footer>form>input{
        width: calc(100% - 1rem );
    }

    .chat__footer-ImageBox {
        bottom: 11rem;
    }
    .chat__footer>form>textarea{

        line-height: 1.5rem;
        font-size: 1.4rem !important;
    }
    .chat__footer{
        height: 7rem;
    }
}

@media screen and (min-width: 914px){
    .chat__backArrow{
        display: none;
    }
}

