.delete__account-modal{
    margin: 4% 3%;
}

.delete__account-modal button{
    background-color: red;
    color: white;
    padding: 8px 20px;
    font-weight: bold;
    margin: 4% 0 0 43%;
}

.delete__account-modal ul{
    font-size: 1.4rem;
    text-align: center;
    margin-top: 15%;
}

.delete__account-modal li{
    margin-bottom: 3%;
}

.delete__account-input{
    margin-top: 15%;
}