/*Navigation*/

.active__navigation__user__profile{
    width: 100% !important;
}


.active__navigation__user__profile ul{
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-right: 2%;
}
.active__navigation__user__profile li{
    font-size: 1.5rem;
    border-radius: 20px;
    padding: 8px 16px;
    margin: 0 !important;
    color: gray;
}

.active__user__profile{
    background-color: #ffc300;
    border-radius: 20px;
    padding: 10px 25px;
    color: white;
    margin: 0 !important;
    font-weight: bold;
}
/*User Profile*/
.profile__link__fake{
    display: none !important;
}
.user__profile img{
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    margin: 3% 0 3% 4%;
}

.user__profile__basic{
    padding: 2% 0 0 8%;
}

.profile__website{
    font-size: 1.4rem;
    text-align: center;
}

.profile__website li{
    padding-bottom: 1%;
}

.profile__website li:hover{
    text-decoration: underline;
}

.user__profile__basic h1{
    font-size: 3.2rem;
}
.user__profile__column3 h2{
    font-size: 2rem;
    padding-top: 3%;
}

.user__profile__column2 li{
    margin-right: 2%;
}

.user__profile__column3 p{
    white-space: pre-line;
    font-size: 1.4rem;
    padding: 2% 10%;
}
.padding-left-35{
    padding-left: 30%;
}

.opacity__profile{
    opacity: 0.6;
}

.user__profile__column5 li{
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;
    padding-bottom: 8%;
}

.user__profile__column5 ul{
    text-align: center;
    padding-left: 30%;
    list-style:square;
}
.user__profile__column7{
    margin-bottom: 10% !important;
}
.user__profile__column7 button{
    padding: 7px 30px;
    font-weight: 700;
    margin: 4% 38%;
}
.user__profile h2{
    text-align: center;
    font-size: 2rem;
    padding-top: 3%;
}

.back__text{
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    padding-top: 0;
    padding-left: 50%;
    color: #ff5733;
    font-size: 2.8rem;
    opacity: 0.5;
    font-weight: 600;
}
.text__back__box{
    position: relative;
}
.user__profile__column6 ul{
    padding-left: 20%;
}
.user__profile__column6 li{
    font-size: 1.8rem;
    padding-bottom: 8%;
}

.vertical__line{
    border-left: 4px solid #ff5733;
    opacity: 0.7;
    height: 6.5rem;
    margin-left: 8%;
}
.career__box h3{
    font-size: 2rem;
    padding-left: 1%;
    padding-top: 1%;
    color: rgb(74, 73, 73);
}

.career__box p{
    font-size: 1.4rem;
    padding: 2% 0 0 5%;
}

.career__box small{
    opacity: 0.6;
    font-size: 1.2rem;
}
.career__box__all{
    padding: 4% 0;
}

.link__icon{
    padding-right: 5px;
}

.company__info__icon{
    font-size: 2rem;
    color: gray;
    padding-right: 2%;
}

.company__info{
    padding-top: 2%;
}

.company__info li{
    padding: 2% 0;
    text-align: center;
    font-size: 1.8rem;
}
/*SNS*/
/* YouTube */
.flowbtn8.fl_yu2{
    border:solid 1px #fc0d1c;
    color:#fc0d1c;
    font-size:24px;	
}
.flowbtn8.fl_yu2:hover{
    border:solid 1px #fc0d1c;
    background:#fc0d1c;
}
/*All*/
.profile__card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 10px;
    margin: 5% 10%;
}

.width-100{
    width: 100%;
}

.unwrite__text{
    font-size: 1.4rem !important;
    opacity: 0.6 !important;
    text-align: center !important;
}

.profile__no__post{
    text-align: center;
    margin-top: 5%;
    font-size: 1.3rem;
    opacity: 0.7;
}

/*Profile Theme*/
.userProfile{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.userProfile__theme{
    height: 20rem;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: lightgray;
}

.photoWrapper{
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    transform: translate(2rem, 13rem);
    background: lightgray;
}

.photoWrapper>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
    cursor: pointer;
}

.infoWrapper{
    padding: 1rem 1rem 1rem 2rem;
}

.userProfile__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFC300;
    margin-bottom: 3.9rem;
}

.userProfile__actions>div{
    padding: .5rem 1.5rem;
    border: 1px solid orange;
    border-radius: 2rem;
}

.userProfile__actions>div:hover{
    opacity: 0.7;
    cursor: pointer;
}

.moreWrapper{
    margin-left: auto;
    padding: .7rem .8rem .3rem !important;
    display: flex;
    width: 27%;
    border: none !important;
}
.moreWrapper input {
    width: 100% !important;
    padding: 4px !important;
    border-right: none;
    border-radius: 5px;
}

.moreWrapper:hover{
    background-color: transparent !important;
    cursor:default !important;
}

.clipIcon{
    color: #FFC300;
}

.followingWrapper{
    color: white;
    background-color: orange;
}

.resumeIconWrapper{
    margin-left: 1rem;
}
.mailWrapper{
    padding: .5rem .8rem !important;
    margin-left: 1rem !important;
}

.followWrapper{
    margin-left: 1rem !important;
}

.infoWrapper>h2{
    font-size: 1.9rem !important;
    font-weight: 800;
}

.infoWrapper>span{
    font-size: 1.5rem;
    color: orange;
}

.infoWrapper>p{
    font-size: 1.5rem;
    margin: .8rem 0;
}

.bioInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.5rem;
    margin-left: -.4rem;
}

.bioInfo>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFC300;
    margin-right: 2rem;
}

.bioInfo>div .MuiSvgIcon-root{
    margin-right: .5rem;
    font-size: 2rem !important;
}

.countInfo{
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    align-items: center;
}

.countInfo span{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    font-weight: 650;
    margin-right: 1rem;
    border-bottom: 1px solid white;
}

.countInfo span>p{
    color: gray;
    font-weight: normal;
    margin-left: .5rem;
}

.countInfo span:hover{
    border-bottom: 1px solid var(--hover);
}

.followedInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 ;
    flex: 1;
}

.followedInfo .MuiAvatar-root{
    width: 4rem;
    height: 4rem;
    margin-right: .5rem;
}

.followedInfo>span{
    color: lightgray;
    font-size: 1.5rem;
}

.blued{
    color: #FFC300 !important;
}

.themeBottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: space-between;
}

.themeSpinner{
    padding:  0 ;
    font-size: 2rem;
    color: var(--main-color);
    margin-left: auto;
}

.icon__check{
    color: green;
}

.icon__envelope__btn{
    background-color: transparent !important;
    border:none !important;
    color: #FFC300;
}

.profile__resume{
    padding: 1rem  0 0 5%;
}
.profile__resume__sns{
    display: flex !important;
}
.profile__resume__sns li{
    padding-right: 2rem;
}

.profile__resume__sns ul{
    display: flex;
}

.profile__resume__sns i:hover{
    cursor: pointer;
}

/*Icon*/
/* ボタン全体 */
.flowbtn8{
    border-radius:50%;
    position:relative;
    display:inline-block;
    width:40px;
    height:40px;
    font-size:17px;
    text-decoration:none;
    transition:.5s;
}

.flowbtn8:hover{
    color:#fff!important;
}

.flowbtn8 i{
    position:absolute;
    top:48%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
}
/* Twitter */
.flowbtn8.fl_tw2{
    border:solid 1px #55acee;
    color:#55acee;
    font-size: 24px;
}

.flowbtn8.fl_tw2:hover{
    border:solid 1px #55acee;
    background:#55acee;
}
/* Instagram */
.flowbtn8.insta_btn2 {
    border:solid 1px #c6529a;
    color:#c6529a;
    font-size:28px;
}

.flowbtn8.insta_btn2:hover{
    border:solid 1px #c6529a;
    background:#c6529a;
}
/* Facebook */
.flowbtn8.fl_fb2{
    border:solid 1px #3b5998;
    color:#3b5998;
    font-size: 24px;
}

.flowbtn8.fl_fb2:hover{
    border:solid 1px #3b5998;
    background:#3b5998;
}

/* LINE */
.flowbtn8.fl_li2{
    border:solid 1px #00c300;
    color:#00c300;
    font-size:28px;
}

.flowbtn8.fl_li2:hover{
    border:solid 1px #00c300;
    background:#00c300;
}

/*Website*/

.profile__resume__website ul{
    display: flex;
}

.profile__resume__website li{
    padding-right: 4%;
    font-size: 15px;
}

.hover__underline:hover{
    text-decoration: underline;
}

/*Career*/
.profile__resume__career{
    margin-left: 5%;
    margin-right: 15%;
}

.profile__resume__career__details{
    display: flex;
    margin-top: 3%;

}

.profile__resume__career__details h2{
    padding-left: 2rem;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}


/*Skills*/

.profile__resume__skills{
    display: flex;
}

.profile__resume__skills h2{
    font-size:2.2rem;
}
.profile__resume__skills h3{
    padding-top: 1rem;
    font-size: 18px;
    padding-left: 20%;
}

.profile__resume__skills__right{
    padding-left: 44%;

}

.profile__resume__quali{
    display: flex;
}

.profile__resume__quali p{
    font-size: 17px;
    color: gray;
}

.profile__resume__quali h4{
    font-size: 24px;
    padding-left: 15%;
    padding-bottom: 1.4rem;
}

@media (max-width: 500px){
    .active__navigation__user__profile li{
        font-size: 1rem;
    }
    .profile__basic__info{
        display: block !important;
        text-align: center;
    }
    .user__profile img{
        margin: 0;
    }
    .user__profile__basic{
        padding: 0;
    }
    .countInfo{
        padding-left: 24%;
    }
    .profile__actionBtn{
        margin-left: 26% !important;
    }
    .company__info__icon{
        font-size: 1.6rem;
    }
    .company__info li{
        font-size: 1.4rem;
    }
    .user__profile__column7 button{
        font-size: 1.2rem;
        padding:7px 10px;
    }
    .user__profile__column7{
        margin-bottom: 18% !important;
    }

    .user__profile__column6 li{
        font-size: 1.5rem;
    }
    .user__profile__column6 ul{
        padding-left: 14% !important;
    }

    .user__profile{
        margin-bottom: 10%;
    }
}