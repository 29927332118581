.admin__dashboard__mini i{
    font-size: 2rem;
    padding: 1.5rem;
    background-color: #f4f7fe;
    border-radius: 50%;
    color: #432bfb;
}

.admin__dashboard__mini{
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 20px;
}

.admin__dashboard__mini-content{
    margin-left: 2rem;
    margin-top: 1rem;
}

.admin__dashboard__mini-content h3{
    font-size: 1.3rem;
    color: gray;
}

.admin__dashboard__mini-content h5{
    font-size: 1.8rem;
    margin-top: 1rem;
}