.admin__dashboard-1-content{
    justify-content: space-evenly;
    margin-top: 2rem;
}

.admin__dashboard-2-chart{
    width: 55%;
    margin: 4rem 0 2rem 0;
}

.admin__dashboard-2-flex{
    display: flex;
    justify-content: space-evenly;
    margin: 0 3rem;
}

.admin__dashboard-3-content{
    justify-content: space-evenly;
}
.admin__dashboard-3-card{
    background-color: white;
    padding: 1.5rem;
    border-radius: 20px;
    margin: 2rem 8rem 0 8rem;
}