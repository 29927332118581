.profile__top__link input{
    width: 100%;
}

.profile__top__link span{
    display: block;
    overflow: hidden;
    padding-right:10px;
}
.profile__top__link button{
    width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.2rem;
}

.check-icon{
    padding-left: 10px;
    font-size: 1.4rem;
    color: gray;
}

.check-icon-success{
    color: green;
}

.profile__top__link-mobile{
    display: none !important;
}

.feed h4{
    text-align: center;
    margin-top: 2rem;
}

@media screen and (max-width: 500px){
    .profile__top__link-pc{
        display: none;
    }
    .profile__top__link-mobile{
        display: block !important;
        
    }
    
}

@media screen and (min-width: 500px){

}