

.footerIcon_wrapper{
    margin: 3% 5% 2% 0;
    width: 100%;
}

.bookmark-icon{
    font-size: 2.5rem;
    color: black;
}