.company__post-list-content{    
    border: 1px solid gray;
    border-radius: 10px;
    margin: 1rem 0;
    padding: 8px 2rem;
    background-color: white;
}

.company__post-list-content h2{
    font-size: 1.6rem;
    margin-bottom: 1rem;
}

.company__post-list-content p{
    font-size: 1.5rem;
    margin-top: 0.5rem;
}

.company__post-list-content-under{
    border-top: 1px solid lightgray;
    margin: 1rem 2rem;
}

.company__post-list-content-under button{
    margin: 1rem 2rem;
    padding: 5px 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    width: 12rem;
}

.company__post-list-flex{
    display: flex;
}

.company__post-list-edit-input select{
    padding: 4px 8px;
    width: 50%;
    margin: 0 1rem;
    border-radius: 5px;
    font-size: 1.5rem;
}

.company__post-list-edit-input input{
    padding: 4px 8px;
    width: 47%;
    margin: 0 1rem;
    border-radius: 5px;
    font-size: 1.5rem;
}
.company__post-list-edit{
    font-size: 1.8rem;
}

.company__post-list-edit-back{
    background-color: white;
    padding: 2rem;
}

.company__post-list-edit textarea{
    overflow-y: scroll !important;
    resize: none !important;
    padding: 4px 8px;
    width: 95%;
    border-bottom: 2px solid gray;
    margin: 2rem 1rem 0 1rem;
    font-size: 1.5rem;
}

.company__post-list-edit-input{
    margin-top: 2rem;
}

.company__post-list-submit{
    padding: 3px 15px;
    margin: 2.5rem 0 1rem 82%;
    background-color: #ffc300;
    color: white;
    border: 0.3px solid black;
    font-weight: bold;
    border-radius: 5px;
    font-size: 1.4rem;
}

@media (max-width: 480px) {
    .company__post-list-submit{
        margin: 2.5rem 0 1rem 55%;
    }
}