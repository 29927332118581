.bottomNav{
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;    
    z-index: 1000;
    background: white;
    padding: 1rem 0;   
}

.bottomNav>nav{
    flex: 1;
    display: flex;
    flex-direction: row;    
}

.bottomNav>nav>a{
    flex: 1;
    text-align: center;
}

.bottomNav .MuiSvgIcon-root {
    font-size: 2.5rem;
    color: gray;
}

.bottomNav__active .MuiSvgIcon-root{
    color: #ffc300 !important;
}

@media screen and (min-width: 501px){
    .bottomNav{
        display: none;
    }
}