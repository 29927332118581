.job__post__img{
    width: 450px !important;
    height: 350px !important;
}

.d-display{
    display: flex;
}

.job__header__body{
    padding-left: 3% !important;
}

.job__header__body p{
    padding: 10% 0 0 20% !important;
    font-size: 1.4rem !important;
}


.job__post__detail p{
    font-size: 1.4rem !important;
}
.statusPost__body table{
    width: 100%;
    border-spacing: 0;
    padding: 0 10% 3% 10%;
    font-size: 1.3rem !important;
}

.statusPost__body table th{
    border-bottom: solid 2px #fb5144;
    padding: 10px 0;
    width: 30%;
}

.statusPost__body table td{
    border-bottom: solid 2px #ddd;
    text-align: center;
    padding: 10px 0;
    width: 70%;
}

.statusPost__body h2{
    padding-left: 5%;
    padding-top: 5%;
    width: 100%;
}

.job__post__icon{
    color: orange;
}

.job__post__text{
    white-space: pre-line;
    padding: 2% 0 3% 8% !important;
}

.statusPost__body h1{
    font-size: 4rem;
}

.statusPost__body p{
    padding-top: 1%;
}

.opacity-6{
    opacity: 0.6;
}

.statusPost__body--message p{
    font-size: 1.4rem;
}

.statusPost__body--message h5{
    font-size:2.5rem;
}

.video-responsive {
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin: 5% 0 -20% 20%;
  }
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 60%;
    width: 70%;
    position: absolute;
}

.statusPost__body span{
    background-color: gray;
    padding: 6px 12px;
}

.website__post{
    font-size: 1.4rem;
    text-decoration: underline;
}

.status__post__top i{
    color: orange;
    font-size: 2rem;
}

.status__post__top p{
    font-size: 1.6rem;
    font-weight: 800;
}

.status__post__watedJob p{
    font-size: 2rem;
    padding: 30% 0 0 5%;
    font-weight: 700;
}

.status__post__intro p{
    font-size: 1.4rem;
    padding-left: 5%;
    white-space: pre-line;
}

.event__post__body h1{
    font-size: 3rem;
}

.event__post__date h2{
    font-size: 3rem;
    opacity: 0.6;
    width: 30% !important;

}

.event__post__date i{
    color: orange;
    font-size: 2.2rem;
}
.d-block{
    display: block !important;
}
.event__date__head{
    font-size: 3.5rem !important;
}

.event__time__place h2{
    width: 100% !important;
    font-size: 2.3rem !important;
}

.event__time__place{
    padding-top: 4%;
}

.event__details h2{
    font-size: 2.3rem;
}

.event__details p{
    padding-left: 5%;
    font-size: 1.5rem;
    white-space: pre-line;
}

.event__details{
    padding-bottom: 5%;
    padding-top: 6%;
}

.freelance__details h2{
    font-size: 2.3rem;
}

.freelance__details p{
    padding-left: 5%;
    font-size: 1.5rem;
    white-space: pre-line;
}
.freelance__money i{
    color:orange;
}

.freelance__money p{
    font-size: 2rem;
}


.padding-left-5{
    padding-left: 5%;
}
.post-border{
    border: 1px solid silver;
    margin-top: 1rem;
}
.post {
    display: flex;
    align-items: flex-start;
    border-radius: 20px;
    padding-bottom: 1rem;
    margin-bottom:3% !important;
}

.post:hover{
    background: var(--copipel-background-2);
}

.post__body{
    flex: 1;
    padding: 1rem;
}

.post__body> img{
    border-radius: 2rem;
    width: 100%;
    height: auto;
    cursor: pointer;    
}
.post__header{
    border-bottom: 1px solid lightgray;
}
.post__headerDescription {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.post__headerDescription>p{
    line-height: 2.1rem;
}

.post__headerText{
    display: flex;
    flex-direction: row;
}

.post__headerText>h3{
    flex: 1;
    font-size: 1.5rem;
    margin: .8rem 0 1rem;
}

.post__badge{
    font-size: 1.8rem !important;
    color: #ff5733 !important;
}

.post__headerSpecial{
    font-weight: 600;
    font-size: 1.2rem;
    color: gray;
}

.post__avatar{
    margin: 1rem 1rem 1rem 1.5rem;
}

.post__footer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.post__headerExpandIcon{
    padding: 1rem;
    border-radius: 50%;
}

.post__headerExpandIcon:hover{
    background: var(--copipel-background-2);
    color: orange;
}

.post__expandList{
    list-style: none;
    padding: 0 0 0 0;
    border-radius: 2rem;
}

.post__expandList li{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;   
    padding: 1.2rem; 
    background: white;
    cursor: pointer;
    min-width: 20rem;
}

.post__expandList li:first-child{
    border-radius: 1rem 1rem 0 0 !important;
}

.post__expandList li:last-child{
    border-radius: 0rem 0rem 1rem 1rem !important;
}

.post__expandList li:hover{
    background: #f5f8fa !important;
}

.post__expandList li>h3{
    font-weight: 400 !important;
}

.post__expandList>li>div{
    margin-right: 1rem;
}

.post__expandList>li>div .MuiSvgIcon-root{
    font-size: 1.8rem;
    padding: .5rem 0rem 0rem 0rem;
}

.tabPost__save__post-status{
    padding-left: 90%;
    margin: -4rem 32% 0 0;
}
.tabPost__save__post-status button{
    border: none;
    background-color: transparent;
}

.delete{
    color: red;
}

.tweetBox__input-actions .MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.post__statusPostHeader{
    flex: 1;
    margin-top: .5rem;
    font-size: 1.4rem;
} 

.bottomWhited{
    border-bottom: none;
    padding-bottom: 0;
}

.post__enter{
    white-space: pre-line !important;
}

.status__post__skills p{
    margin: 2rem 4% 0 0 ;
}

.statusPost__body--date{
    margin-top: 5%;
}

.post__keyword-status{
    margin-top: 3rem;
}

@media (max-width: 320px){

}

@media (min-width: 321px) and (max-width: 480px){

    .statusPost__body{
        padding-left: 3%;
    }
    .statusPost__body--message h1{
        font-size: 2.8rem;
        padding: 5px 0;
    }
    .post__footer{
        padding-left: 8%;
    }
    .event__post__body h1{
        font-size: 2.8rem;
    }
    .event__post__date h2{
        font-size: 2.5rem !important;
    }
    .event__time__place h2{
        font-size: 2rem !important;
        padding-left: 6rem;
    }
    .event__details h2{
        font-size: 2rem ;
    }
    .freelance__post__body h1{
        font-size: 2.8rem;
        padding: 5px 0;
    }
    .freelance__details h2{
        font-size: 2rem;
    }
    .freelance__post__body{
        padding-bottom: 10%;
    }
    .tabPost__save__post-status{
        margin-top: -2.6rem;
    }
}