.mail__btn i{
    font-size: 1.5rem;
    padding: 0.4rem;
    color: #ffc300;
}

.mail__btn__link{
    color: #ffc300 !important;
    text-align: center;
}

