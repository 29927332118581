.sidebar{
    flex: 0.2;
    padding-top: 2rem;
    padding-left:2rem;
    padding-right:2rem;
    min-width: fit-content;
}

.sidebar__twitterIcon{
    color: orange;
    font-size: 3rem !important;
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: -.3rem;
}

.sidebar__tweet{
    background-color: orange !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 3rem !important;
    height: 5rem;
    margin-top: 2rem !important;

    width: 100%;
}

.sidebar__tweet>div{
    max-width: 3rem;
    margin-top: .3rem;
    margin-left: .3rem;
}
.sidebar__tweet:hover{
    opacity: 0.7;
}
.sidebar__info{
    font-size: 1.7rem;
    padding: 1.5rem 0 0 1rem;
    font-weight: 600;
    border-bottom: 1px solid gray;
}
.sidebar__info:hover{
    opacity: 0.7;
}

.email__settings{
    width: 100%;
    display: flex;
}

@media screen and (max-width: 1282px){
    .sidebar__tweet{
        width: 5rem !important;
    }
    .sidebar{
        flex: unset !important;
    }
    .sidebar__tweet span{
        display: none;
    }
}


@media screen and (max-width: 500px){
    .sidebar__tweet{
        margin-left: 1rem !important;
    }
    .sidebar{
        display: none;
    }

}

@media screen and (min-width: 1282px){
    .sidebar__tweet>div{
        display: none
    }
}


.sidebarAccount__wrapper{
    display: flex;
    flex-direction: row;
    border-radius: 3rem;
    align-items: center;
    margin-top: 15%;
}


.sidebarAccount__ava{
    border-radius: 50%;
    padding: 1rem;
}

.sidebarAccount__wrapper:hover{
    background-color: #FFC300;
    opacity: 0.4;
    cursor: pointer;
}

.sidebarAccount__userData{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.sidebarAccount__userData h2:nth-child(2){
    color: black;
    font-weight: 450;
}

.sidebarAccount__expandIcon{
    margin-right: 1rem;
    font-size: 2rem;
}

.MuiPopover-paper  {
    border-radius: 2rem !important;
}

.logoutBtn{
    cursor: pointer;
}

.sideAccount__btn{
    width: 100%;
    padding: 5px 10px;
    border: none;
    background-color: transparent;
}

.sidebar__icon{
    width: 50px;
    height: 50px;
    margin-left: 12%;
}
.setting-icon{
    font-size: 1.8rem;
    position: absolute;
    left: 0;
    margin-left: 25%;
}
@media screen and (max-width: 1282px){
    .sidebarAccount__userData, .sidebarAccount__expandIcon {
        display: none;
    } 
}