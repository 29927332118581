.sidebarOption{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.sidebarOption:hover{
    color:#FFC300;
    transition: color 100ms ease-out;
    opacity: 0.7;
}

.sidebar__active{
    color: #FFC300;
}

.sidebarOption>h2{
    font-weight: 700;
    font-size: 1.9rem;
    margin-right: 2rem;
}

.sidebarOptionIcon{
    font-size: 2.4rem;
    padding: 1.4rem 1.4rem 1.4rem 2rem;  
}
.hide{
    display: none;
}
.sidebar__unread{
    height: 2rem;
    width: 2rem;
    text-align: center;
    border-radius: 50%;
    background-color: red;
    color: white;
}
@media screen and (max-width: 1282px){
    .sidebarOption>h2{
        display: none;
    }
    .sidebarOption > .MuiSvgIcon-root{
        margin-left: auto;
    }
    .sidebarOption{
        width: fit-content;
    }
}