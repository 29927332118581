.timeLine__home{
    display: flex;
}

.company__search{
    margin-left: 5%;
    border-left: 1px solid lightgray;
}

.timeLine__load__btn{
    background-color: transparent;
    border: none;
    color: black;
}

.refresh__btn{
    font-size: 2rem;
}

.jobs_timeLine-btn{
    display: flex;
    margin: 5px 0 0 5px;
}

.jobs_timeLine-btn button{
    padding: 7px 12px;
    margin-right: 1rem;
    border: none;
    background-color: #eee;
    border-radius:5px;
    font-weight: 700;
    opacity: 0.8;
}

.jobs_timeLine-btn button:hover{
    text-decoration: underline blue;
    color: blue;
}

/*Home Back*/
.home__back__image{
    width: 100%;
    background-size: cover;
    height: 30rem;
    background: 
    linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url('../../assets/homeBack.png');
    background-position-x: center;
    background-position-y:center;
}


.home__back__image h2{
    color: white;
    text-align: center;
    font-size: 3.5rem;
    padding-top: 14%;
}
.home__back__btn{
    margin-top: 2rem;
}

.home__back__image span{
    border-radius: 20px;
    text-align: center;
}

.home__back__btn button{
    margin: 0 auto;
    display: block;
    padding: 9px 22px;
    font-size: 1.6rem;
    font-weight: bold;
    color: white;
    background-color: #ff5349;
    border: #ffc300;
    box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

.home__back__btn button:hover{
    opacity: 0.7;
}

.home__back-mobile{
    display: none;
}

/*Kind of Job*/
.timeline__content__box{
    padding: 3rem 5%;
    border-bottom:  1px solid lightgray;
    margin-top: 5%;
}

.kindof__job__timeline ul{
    font-size: 1.5rem;
    margin-left: 5rem;
}

.kindof__job__timeline li{
    margin: 0 0 1.5rem 6rem;
}

.kindof__job__timeline-icon{
    padding-right: 1rem;
}


.feed {
    flex: 1;
    border-right: 1px solid #e6ecf0;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

.feed__header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--copipel-background);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1px;
}

.feed__header-ava{
    margin-right: 2rem;
}

.feed__header > h2 {
    font-size: 2rem;
    flex: 1;
    font-weight: 800;
}

.feed__loader{
    width: 100%;
    padding-top: 2rem;
    display: flex;
    justify-content: center !important;
}

.feed::-webkit-scrollbar{
    display: none;
}

.profile__header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid #e6ecf0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .7rem 1rem;
}

.forFollow{ 
    border-bottom: 1px solid white;
}

.profile__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.profile__backArrow:hover{
    background: whitesmoke;
}

.profile__header .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: #ffc300;
}

.profile__title{
    display: flex;
    flex-direction: column;
}

.profile__title_title{
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.profile__header h2{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}

.profile__header span{
    font-size: 1.3rem;
    color: var(--twittie-light);
}


.status__header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid #e6ecf0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .7rem 1rem;
}


.status__backArrow{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.status__backArrow:hover{
    background: whitesmoke;
}

.status__header .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: #ffc300;
}

.status__title{
    display: flex;
    flex-direction: column;
}

.status__title_title{
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.status__header h2{
    font-size: 1.9rem;
    font-weight: 800;
    margin-right: 1rem !important;
}

.status__header span{
    font-size: 1.3rem;
    color: var(--twittie-light);
}

.non__posts{
    font-size: 2rem;
    padding: 5% 0 0 34%;
    opacity: 0.4;
    font-weight: 700;
}

.header-text{
    padding: 1rem 2rem;
    border-left: 4px solid #ffc300;
}

/*Info*/
.timeline__info-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 12px 24px;
}

.timeline__info-card-margin{
    margin: 2% 5%;
}

.timeline__info-card h3{
    text-align: center;
}
.info-card{
    width: 16rem;
}

@media (max-width: 1282px){
    .feed {
        flex: 1 !important;
    }
}

@media (min-width: 500px){
    .feed__header-ava{
        display: none;
    }
    
}

@media (max-width: 480px) {
    .feed{
        margin-bottom: 12%;
    }
    .timeline__info-card{
        padding: 10px 0;
    }
    .home__back-mobile{
        display: block;
    }
}

@media (max-width:320px){
    .home__back__btn{
        margin: 6% 0 5% 22%;
    }
}
