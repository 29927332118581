.sidebarChat{
    flex: 2;
    border-right: 1px solid var(--twittie-background);
}

.noned{
    display: none;
}

.sidebarChat__header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--twittie-background);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.sidebarChat__header>h2{
    font-size: 2rem;
    flex: 1;
    font-weight: 800;
}

.sidebarChat .MuiSvgIcon-root{
    font-size: 2.5rem;
    color: var(--twittie-color);
    margin-left: 1rem;
    margin-right: .1rem;
    cursor: pointer;

}



.sidebarChat__search{
    display: flex;
    align-items: center;
    height: 3.9rem;
    padding: .8rem;
    border-bottom: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    border-radius: 2rem;
    background-color: var(--twittie-background);
    border: 1px solid var(--twittie-background);
}

.sidebarChat__searchContainer.pressed{
    border: 1px solid var(--twittie-color) !important;
    background-color: white !important;
}

.sidebarChat__searchContainer >input{
    border: transparent;
    line-height: 2.8rem;
    outline: none;
    width: calc(100% - 7rem);
    background: var(--twittie-background);
}

.sidebarChat__searchContainer >input.inputPressed{
    background: white !important;    
}

.sidebarChat__searchContainer > .MuiSvgIcon-root {
    color: var(--twittie-light);
    padding: .5rem;
    cursor: pointer;
}

.sidebarChat__searchContainer.pressed > .MuiSvgIcon-root{
    color: var(--twittie-color);
}

.sidebarChat__chats{
    background-color: white;
    overflow: hidden;
    flex: 1;
}


.sidebarChat__noExist img{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.sidebarChat__noExist h2{
    text-align: center;
    margin: 10% 0 2rem 0;
    font-size: 2rem;
}

.how__toStart button{
    background: transparent;
    border: none;
}
.how__toStart p{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2px;
}
.chat__question-icon{
    font-size: 2rem;
}

.how__toStart{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}


@media screen and (max-width: 768px){
    .sidebarChat__noExist img{
        width: 70%;
    }
    .how__toStart{
        width: 35%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px){
    .sidebarChat__noExist img{
        width: 50%;
    }
    .how__toStart p{
        font-size: 2rem;
    }
}

