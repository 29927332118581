.widget__post__side img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 1rem;

}

.widget__card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 10px;
    margin: 5% 0 5% 2%!important;
}

.widget__post{
    padding-top: 3%;
}

.d-block{
    display: block !important;
}

.widget__follow span{
    padding-bottom: 5%;
}

.display-none{
    display: none !important;
}

.wiget__copy__btn input{
    width: 0.1px;
    border: none;
}

.wiget__copy__btn button{
    width: 100%;
    margin-bottom: 6%;
    padding: 3px 6px;
}

.widget__sns li{
    margin-right: 2%;
}

.mail__widget__wrapper{
    margin-bottom: 2%;
    width: 75%;
}
.follow__widget__wrapper{
    width: 85% !important;
}

.profile__jump__wrapper{
    color: #ffc300;
}


.follow__widget__wrapper>div{
    padding: .5rem 1.5rem;
    border: 1px solid orange;
    border-radius: 2rem;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: -2rem;
}

.follow__widget__wrapper:hover{
    cursor: pointer;
}
.follow__wrapper{
    background-color: #ffc300;
    color: white;
}

.followed__wrapper{
    background: transparent;
    color: #ffc300;
}

@media screen and (max-width: 500px){
    .widget__post{
        display: none;
    }
}