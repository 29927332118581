.forget__password{
    background-color: #f5f5f5;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.forget__password form{
    padding-top: 10px;
	font-size: 14px;
	margin-top: 30px;
}

.card-body{
    background-color: white;
    border: 1px solid lightgray;
    padding: 10%;
}

.card-title{ 
    font-weight:300;
    font-size: 1.7rem;
    text-align: center;
    margin-bottom: 2rem;
}

.btn{
	font-size: 14px;
    display: block;
    margin: 20px auto 0 auto;
    padding: 8px 18px;
    background-color: #ff5733	;
    border: none;
    color: white;

}

.card-text p{
    font-size: 1rem !important;
}

.forget__password span{
	font-size:14px;
}

.form-group input{
    padding: 8px 10px;
    display: block;
    margin: 20px auto 0 auto;
    width: 90%;

}

.form-group input:focus{
    outline: none;
}

.card-body p{
    font-size: 1.5rem;
    padding-top: 4%;
}

.logo__form__page{
    display: block;
    margin: 0 auto 1rem auto;
    width: 35%;
}


input[type=number] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

.width-50rem{
    width: 50rem;
}

.resend__code{
    border-top: 1px solid gray;
    margin: 0 auto 1rem auto;
    width: 50%;
    margin-top: 2rem;
}
.resend__code button{
    padding: 3px 13px;
    background-color: transparent;
    display: block;
    margin: 20px auto 0 auto;
}

@media screen and (max-width: 500px){
    .form-group label{
        font-size: 1.2rem;
    }
    .logo__success__logo{
        padding-left: 10%;
    }
}