.active__navigation{
    width: 100% !important;
    margin-top: 4%;
    z-index: 1000;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.active__navigation::-webkit-scrollbar {
    display: none; 
}


.active__navigation ul{
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-right: 2%;

}
.active__navigation li{
    font-size: 1.3rem;
    border-radius: 20px;
    padding: 8px 16px;
    margin: 0 !important;

}

.active__navigation span:hover{
    color: #FFCC66 !important;
}

.active{
    background-color: #FFC300 !important;
    border-radius: 20px;
    padding: 10px 35px;
    color: white;
    margin: 0 !important;
    font-weight: bold;
}
/*UnAuth Top bar*/
.unAuth__topBar{
    width: 100% !important;
    height: 6rem;

}
.unAuth__topBar__bottom{
    border-bottom: 1px solid lightgray;
    width: 100%;
    margin-bottom: 1rem;
}

.unAuth__topBar__Auth{
    margin: 1rem 3rem 0 auto;

}
.unAuth__topBar__Auth button{
    padding: 7px 20px;
    color: white;
    background-color: #FFC300;
    border:  orange 1px solid;
}

.unAuth__topBar__img img{
    width: 200px;
    margin: 10px 0 6px 0;
}

.unAuth__topBar__content button{
    margin-top: 1rem;
    padding: 20px 0;
    border: none;
    width: 100%;
    background-color: transparent;
    font-weight: bold;
    font-size: 1.4rem;
    color: black;
}

.unAuth__topBar__content button:hover{
    background-color: whitesmoke;
}

.menuBar__btn{
    background-color: transparent;
    border: none;
    margin-right: 5%;
    padding: 2px 12px;
    border-radius: 50%;
    margin-top: 2%;
}

.menuBar__btn:hover{
    background-color: lightgray;
}

.navigation{
    width: 100%;
    height: 4rem;
}


.topBar__menu{
    color: black;
}



@media (max-width: 320px){

    .active__navigation li{
        font-size: 1rem;

    }

    .active__navigation ul{
        margin-left: 17rem;
    }

    .active{
        font-size: 1.2rem;
        padding: 8px 15px;
    }
    .unAuth__topBar__img img{
        width: 140px;
        margin: 10px 0 10px -5px;
    }
    .unAuth__topBar__Auth button{
        padding: 4px 10px;
    }

    .active__navigation__search ul{
        margin-left: 22rem;
    }

}

@media (min-width: 321px) and (max-width: 480px){
    .active__navigation__timeline li{
        font-size: 1rem;
    }
    .active__navigation__timeline{
        margin-top: -2.4rem;
    }
    .menuBar__btn{
        margin-right: 1%;
    }

    .unAuth__topBar__img img{
        width: 140px;
    }
    .unAuth__topBar__Auth button{
        padding: 4px 20px;
    }

    .active__navigation ul{
        margin-left: 15rem;
    }
    .active__navigation__search ul{
        margin-left: 22rem !important;
    }
    .active{
        padding: 8px 15px;
    }

}

@media (max-width:700px){
    .active__navigation ul{
        margin-left: 15rem;
    }
}