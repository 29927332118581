.post__front__img{
    width: 300px !important;
    height: 240px !important;
    border-radius: 20px;
}

.post__front__img__div{
    margin-right: 5% !important;
}

.post__headerDescription p{
    font-size: 2rem;
    font-weight: 600;
}

.post__enter__top{
    padding-top: 2%;
}

.post__enter__top p {
    font-weight: 650;
    font-size: 1.2em;
}

.post__enter__top i{
    color: orange;
    font-size: 20px;
    padding-top: 2px;
}

.post__enter__top__text{
    font-size: 14px;
}

.each__post__enter__top{
    display: flex;
    padding-top: 1%;
}

.each__post__enter__top p{
    padding-left: 2%;
}
.post__enter{
    font-size: 2rem !important;
    padding-bottom: 2%;
}
.post__headerText h3{
    margin-top: 1.5rem !important;
}

.tabPost__save__post-front{
    margin: -3.4rem 0 0 85%;
}

.tabPost__save__post-front button{
    background-color: transparent;
    border: none;
}

.isDone__btn__post{
    border: none;
    padding: 8px;
    background-color: transparent;
    position: absolute;
    right: 0;
    margin: -2rem 2rem 0 0 ;
    font-size:2rem;
}

.modal__alert__meg{
    padding: 5% 25%;
}

.modal__alert__meg h2{
    font-size: 2rem;
}

.modal__alert__meg p{
    padding: 4% 0 0 2%;
    font-size: 1.2rem;
    color: red;
}

.isDone__btn button{
    width: 16rem;
    margin: 2rem 33%;
    padding: 5px 10px;
    font-weight: 550;
    border-radius: 4px;
}

.isDone__btn__complete{
    background-color: green;
    color: white;
}

.post__expandList input{
    width: 0.1px;
    border: none;
    position: absolute;

}
.post__expandList button{
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: left;
}

.post__expandList li{
    color: black;
    font-size: 1.4rem;
}

.post-icon{
    font-size: 1.6rem !important;
    padding-right: 1.5rem;
    color: black;
}

.post-success-icon{
    color: green;
}

.kindJob__text{
    font-size: 1.5rem !important;
    color: white;
    margin: 2% 0;
}

.post__headerDescription span{
    background-color: gray;
    padding: 6px 12px;
    border-radius: 5px;
}
.post__headerDescription{
    margin-left: 10%;
    margin-top: 1.5rem;
}

.d-flex{
    display: flex;
}

.padding-left-3{
    padding-left: 3%;
}

.post__reqSkill{
    margin-top: 3%;
}

.post__skills p{
    font-size: 1.6rem;
    opacity: 0.8;
    padding: 4px 8px;
    background-color: whitesmoke;
    border: 1px solid gray;
    margin-right: 2%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    font-weight: bold;
    height: 2.8rem;
}

.text__deco1{
    font-weight: 500 !important;
}

.balloon3-right {
    position: relative;
    display: inline-block;
    margin: 1.5em 15px 1.5em 0;
    padding: 0 2px;
    width: 160px;
    height: 30px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    background: #ffcc75;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .balloon3-right:before {
    content: "";
    position: absolute;
    top: 50%;
    right: -24px;
    margin-top: -15px;
    border: 15px solid transparent;
    border-left: 15px solid #ffcc75;
    z-index: 0;
  }

.post__wanted__job{
    width: 100%;
}
.post__wanted__job p{
    margin: 2rem 0 0 3rem !important;
    width: 100%;
}

.width-100{
    width: 100%;
}

.post__website{
    font-size: 1.6rem !important;
    font-weight: 400;
    padding-top: 3%;
    opacity: 0.8;
}

.post__ExpandIcon button{
    background-color: transparent;
    border: none;
    padding: 1rem;
    border-radius: 50%;
}

.post__ExpandIcon button:hover{
    background-color: lightgray;
}

.expandIcon{
    font-size: 2rem !important;
    color: black;
}

.post-icon{
    font-size: 1.2rem
}
.post__apply-btn{
    display: table;
    margin: 0 auto;
    padding: 7px 30px;
    background: #ffc300;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
}

.hire__section{
    margin: 2% 0 2% 1rem;
    font-size: 1.4rem !important;
}

.under__recruite__mark{
    color: green;
}

.done__recruite__mark{
    color: red;
}

.recruite__mark{
    font-size: 1.4rem;
    margin-top: 1.5rem;
    margin-left: 85%;
}

.recruite__mark-icon{
    padding-right: 5px;
}
.post__keyword ul{
    display: flex;
    margin-left: 5rem;
}

.post__keyword li{
    font-size: 1.3rem;
    border: none;
    color: black;
    border-left: 2px solid black;
    border-right: 2px solid black;
    margin-right: 1.5rem;
    padding: 0 1.5rem;
}

.post__keyword-link:hover{
    opacity: 0.6;
}

.post__keyword-front{
    margin-top: 1rem;
}

.mobile-flex{
    display: flex;
}

.qualification__short p{
    color: black;
    font-size: 1.3rem;
    margin-top: 2rem;
}

@media (min-width: 321px) and (max-width: 480px){
    .mobile__img{
        display: block !important;
    }
    .tabPost__save__post{
        padding-left: 54%;
    }
    .post__enter{
        font-size: 2rem !important;
    }
    .kindJob__text{
        font-size: 1.2rem !important;
    }
    .post__enter__top p{
        font-size: 1.4rem;
    }
    .post__skills p{
        font-size: 1.3rem;
    }
    .recruite__mark{
        margin-left: 78%;
        margin-bottom: 3rem;
    }
    .post__keyword-status{
        margin-left: 1rem;
    }
    .post__keyword ul{
        margin-left: 1rem;
        margin-top: 2rem;
    }
    .post__keyword li{
        padding: 0 5px;
        margin-right: 0.5rem;
    }
    .post__headerText{
        margin-top: -4rem !important;
    }
    .jobs_timeLine-btn button{
        padding: 4px 8px;
        font-size: 0.8rem;
    }
    .post__keyword li{
        font-size: 1.1rem;
    }
}

@media (max-width: 480px){
    .post__body{
        margin: 5rem 0 0 1rem;
    }
    .post__header{
        margin-bottom: 2rem;
    }
    .post__skills p{
        padding: 3px 6px;
        background-color: whitesmoke;
        border: 1px solid gray;
        margin-right: 2.5%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        font-weight: bold;
        height: 2.5rem;
    }
}