* {
    margin: 0;
}


html{
    font-size: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

:root{
    --copipel-background: #e6ecf0;
    --copipel-background-2: #f5f8fa;
    --main-color: #ffc300;
    --hover: white;
    --hover-2: white;
    --red: rgb(224, 36, 94); 
}

.app{
    display: flex;
    height: auto;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;

    flex-direction: column;
}

.app__mainContent{
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.react-responsive-modal-modal{
    top: 4.5vh;
    height: fit-content;
    min-height: 85vh;
    border-radius: 2rem;
    padding: 0;
    width: 85vh;
}

.react-responsive-modal-closeButton{
    display: none;
}

.MuiCircularProgress-colorPrimary{
    color: #ffc300 !important;
}

.app__main{
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.app__main__cv{
    display: flex;
}

.app__main__cv__left{
    position: absolute;
    left: 0;
}

.app__main__cv__right{
    padding-left:10%;
}

.MuiPopover-paper{
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px !important;
}

.emoji-picker-react input.emoji-search{
    border: 2px solid #ffc300 !important;
    border-radius: 3rem;
}

ul{
    list-style: none;
    padding: 0;
}

.tabbarMenu .react-swipeable-view-container .MuiBox-root-6 {
    padding: 0;
}

.addMoreLeftPadd{
    padding-left: 2rem;
}
button:hover{
    cursor: pointer;
}

.app__main__search{
    position: absolute;
    right: 0;
    margin-right: 10%;
    margin-top: 4%;

}

.app__admin{
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    background-color: #f4f7fe;
}

.app__admin__wrapper{
    overflow-y: scroll;
    width: 100%;
    padding: 1.6rem 0;
    padding-top: 0;
    animation: fadeIn .5s ease;
    position: relative;
}

.d-block{
    display: block !important;
}

.d-flex{
    display: flex !important;
}
.widget__empty{
    width: 20% !important;
}

a{
    text-decoration: none;
    color: black;
}

.cursor:hover{
    cursor: pointer;
}

.underline:hover{
    border-bottom: 1px solid black;
}

.text-center{
    text-align: center;
}

.opacity:hover{
    opacity: 0.7;
}

@media screen and (max-width: 500px){
    .app__mainContent{
        height: calc(100vh - 4.5rem);        
    }
    .widget__empty{
        display: none;
    }
    .react-responsive-modal-modal{
        width: 32rem;
    }
}