/*Sign up*/
.auth__form-flex{
    display: flex;
}

.auth_form{
    width: 100%;
}

.auth__form-content{
    margin: 15% 0 0 5%;
}

.auth__form-content h2{
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.auth__form-content-input input{
    width: 100%;
    margin-bottom: 2rem;
    padding: 10px 10px 10px 15px;
    font-size: 1.5rem;
    border-radius: 20px;
    border: 0.5px solid lightgray;
    background:whitesmoke;
    color: gray;
}

.auth__form-content-signup input{
    width: 80%;
}
.auth__form-content-margin{
    margin-top: 3rem;
}
.auth__form-signup-img{
    width: 40%;
}

.auth__form-content-logo img{
    height: 2.8rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: 3rem;
}
.auth__form-content-btn{
    text-align: center;
}
.auth__form-content-signup-btn{
    text-align: center;
    margin-top: 3rem;
}

.auth__form-content-btn button {
    background-color: #ffc300;
    color: white;
    font-weight: bold;
    border:none;
    padding: 10px 20px;
}
.auth__form-border{
    border-bottom: 2px solid gray;
    margin: 0 30%;
}
.auth__form-info{
    margin-top: 4rem;
    font-size: 1.4rem;
}

.auth__form-info span{
    font-weight: bold;
    opacity: 0.8;
}

.auth__form-change1{
    margin-bottom: 2rem;
}
.auth__form-change2{
    margin-top: 2rem;
} 
.auth__form-info-flex{
    display: flex;
}
.auth__form-info-center p{
    text-align: center !important;
}
.auth__form-signup-content{
    margin: 4% 2rem;
    text-align: center;
    width: 50%;
}

.auth__form-signup-content-logo img{
    width: 20rem;
    margin-bottom: 2rem;
}

.auth__form-use{
    display: flex;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-left: 10%;
}

.auth__form-info p{
    color: gray;
    text-align: center;
}
.auth__form-use input{
    margin-top: 2.5px;
}

.auth__form-content-signup-center{
    margin-left: 30%;
    margin-top: 2rem;
}

/*Logout*/
.sign__out{
    width: 100%;
    margin: 10% 0 0 0;
}

.sign__out button{
    font-size: 1.8rem;
    padding: 8px 20px;

}

.sign__out h2{
    font-size: 2rem;
    text-align: center;
}

.error{
    position: relative;
    width: -webkit-fill-available;
    padding: 1rem 2rem;
    color: red;
    border: 0.2rem solid red;
    outline: none;
    box-shadow: none;
    margin: .8rem 0;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    font-weight: 300;
    border-radius: 2rem;  
}

/*Media Query*/
@media (min-width: 601px) and (max-width: 991px){
    .auth__form-flex img{
        width:70%;
        height: 100%;
    }
    .auth__form-content{
        margin: 5% 0 0 -13rem;
    }
    .auth__form-signup-content{
        margin: 5% 0 1rem 0;
    }
    .auth__form-signup-img{
        width: 100%;
        margin-right: -8rem;
    }
    .auth__form-content-signup-center{
        margin-left: 4rem;
    }
    .auth__form-signup-content-logo{
        width: 2.5rem;
    }
}
@media (max-width: 480px){
    .auth__form-content{
        margin: 0;
    }
    .auth__form-signup-content{
        margin: 7rem 0 0 1rem !important;
        width: 90%;
    }
    .auth__form-content-signup-center{
        margin-left: 10%;
    }
    .auth__form-flex img{
        display: none;
    }
    .auth__form-content{
        margin: 30% 0 3rem 1rem;
        width: 90%;
    }
    .auth__form-border{
        margin: 0 28% 0 32%;
    }
    .auth__form-content-input{
        margin: 2rem 2rem 0 2rem;
    }
    .auth__form-info{
        margin-top: 8rem;
    }
    .auth__form-flex{
        display: block;
    }
    .auth__form-info{
        font-size: 1.4rem !important;
    }
    .auth__form-use input{
        margin-top: 3px;
        margin-right:3px;
        height: 10px;
        width: 10px;
    }
}



@media (max-width: 600px){
    .auth__form-flex img{
        display: none;
    }
    .auth__form-info{
        margin-bottom: 18%;
    }
    .auth__form-content-signup{
        width: 100%;
    }
}