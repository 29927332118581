.popover-content{
    z-index: 1000;
    position: absolute;
    left: 0;
    margin-left: 66%;
}

@media (max-width: 480px){
    .popover-content{
        margin-left: 36%;
    }
}