.mobile-flex{
  display: flex;
}
.search__box{
  width: 100%;
}

.search__box-content{
  border-radius: 20px;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 2rem;
  position: relative;
}

.search__box-content select{
  width: 11rem;
  padding: 1rem;
  border: none;
  border-right: 1px solid lightgray;
  color: gray;
  outline: none;
}

.search__box-content option{
  color: black;
}

.search__box-content option:disabled{
  color: lightgray;
}
.search__box-content2 input{
  width: 22rem;
  padding: 1rem;
  margin-left: 4px;
  border: none;
  outline: none;
}

.search__box-content-btn button{
  background-color: lightsalmon;
  border-radius: 30px;
  padding: 10px 20px;
  color: white;
  border: none;
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

.search__box-content-user input{
  padding: 1rem;
  margin-left: 4px;
  width: 80%;
  outline: none;
  border: none;
}

.search__box-content-user button{
  background-color: lightsalmon;
  border-radius: 30px;
  padding: 10px 20px;
  color: white;
  border: none;
}

.search__box-content-data{
  padding: 1rem;
  margin-left: 4px;
  border: none;
  border-right: 1px solid lightgray;
  color: gray;
}

.search__box-content2-event{
  width: 22rem;
}

/*Word Search*/
.words__search-flex{
  display: flex;
}

.words__search-flex button{
  padding: 3px 10px;
  background-color: transparent;
  margin: 1rem;
  border: none;
  color: black;
}

.words__search-flex button:hover{
  text-decoration: underline;
}

.words__search-content{
  position: relative;
  margin: 5em 0;
  padding: 0.5em 1em;
  border: solid 3px #eee;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.words__search-content .words__search-content-title{
  position: absolute;
  display: inline-block;
  top: -13px;
  left: 10px;
  padding: 0 9px;
  line-height: 1;
  font-size: 19px;
  background: #FFF;
  color: #ffc300;
  font-weight: bold;
}

@media (max-width: 480px){
  .mobile-flex{
    display: block;
  }
  .search__box-content select{
    border: none;
  }
  .search__box-content1{
    border-bottom: 2px dotted gray;
    padding-bottom: 4px;
  }
  .search__box-content2{
    padding-top: 4px;
  }
  .search__box-content-btn button{
    border-radius: 30px;
    padding: 8px 15px;
    color: white;
    border: none;
    position: absolute;
    right: 0;
    margin-right: 2rem;
    font-size: 1.2rem;
  }
  .search__box-content select{
    width: 9.2rem;
    font-size: 1.2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    background-color: transparent;
  }
  .search__box-content-data{
    border: none;
    width: 10rem;
    margin-top: -10px;
    background-color: transparent;
    color: gray;
    border-radius: 0;
    margin-left: 0;
    padding: 0;
  }

  .words__search-flex button{
    padding: 0;
    margin: 6px;
    font-size: 1.2rem;
  }
  .words__search{
    margin: 0 1rem;
  }
  .mobile-btn-margin button{
    margin-top: -2rem;
  }
  .search__box-content{
    margin: 1rem;
  }

  .search__box-content input[type='date']::before{
    content: attr(placeholder);
    font-size: 1.2rem;
    width: 100%;
    color: gray;
  }

  .search__box-content input[type='date'].filled::before{
    content: '' !important;
    color: white;
  }
  .search__box-content input[type='date']:not(.filled)::before{
    color: gray;
    content: attr(placeholder);
  }
}
