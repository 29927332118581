/*Get User*/
#userTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
  }
  
#userTable td, #userTable th {
  padding: 1.5rem 3rem;
}

#userTable td{
  font-size: 1.5rem;
}

#userTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: gray;
    font-size: 1.2rem !important;
    border-bottom: 1px solid lightgray;
}

.userAdmin__popOver{
  z-index: 100;
  background-color: red;
  padding: 10%;
  width: 100%;
  border-radius: 10px;
}

.mailTo__link:hover{
  text-decoration: underline !important;
}

.mailTo__link{
  font-size: 1.4rem !important;
}

.get__user button{
  border: none;
  border-left: 1px solid #eee;
  background-color: transparent;
}