.conversationInfo{
    flex: 3;
    display: flex;
    flex-direction: column;
    border-right:1px solid var(--copipel-background);
}

.conversationInfo__header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--copipel-background);
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.conversationInfo__header>h2{
    font-size: 2rem;
    flex: 1;
    font-weight: 800;
    margin-right:2rem
}

.conversationInfo__header-back{
    padding: .65rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.conversationInfo__header-back:hover{
    background: #f5f8fa;
}

.conversationInfo__header .MuiSvgIcon-root {
    font-size: 2.5rem !important;
    color: #ffc300;
}

.conversationInfo .MuiButton-root{
    text-transform: unset;
    font-size: 1.3rem;
    color: #ffc300 !important;
    border-radius: 2rem;
    border: 1px solid orange;
    padding: 0rem 1rem ;
    max-height: 2.7rem;
}

.conversationInfo .MuiButton-root:hover{
    background: #e8f5fe;
}

.userActions{
    padding: 0;
    list-style-type: none;
}

.userActions>li{
    font-size: 1.3rem !important;
    text-align: center;
    font-weight: 450;
    padding: 1rem;
    color: orange;
    cursor: pointer;
    border-top: 1px solid var(--copipel-background);
}


.red{
    color: rgb(224, 36, 94) !important;
}

.red:hover{
    background: #e0245e0d !important;
}