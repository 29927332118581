.sidenavigation {
    width: 250px;
    height: 100%;
    background: black; 
    position: relative;
    padding-top: 1rem;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    background-color: white;
  }
.sidenavigation h3{
    font-size: 2.5rem;
    text-align: center;
    border-bottom: 2px solid lightgray;
    padding-bottom: 3rem;
    margin-top: 1rem;
}
.sidenavigation-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    font-weight: bolder;
}

.sidenavigation-menu {
    padding: 1.6rem 0 1.6rem 3.2rem;
    display: block;
    color: gray;
    font-weight: bolder;
    opacity: .8;
    font-size: 1.5rem !important;
}

.sidenavigation-menu:hover{
    background: white;
    text-decoration: none;
    opacity: 1;
}

.sidenavigation-menu-active {
    background: white;
    opacity: 1;
    border-right: 6px solid #432bfb;
    color: #432bfb ;
}

.admin__sidebar-icon{
    padding-right: 1rem
}